function toSnakeCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/-/g, '_')
    .replace(/[^\w]+/g, '');
}

module.exports = {
  toSnakeCase,
};
