import { memo, useState } from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Divider,
  Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAlertsContext } from '../../../providers/AlertsProvider';

const AlertRow = memo(({ item, deleteAlert }) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { handleAlertEdit } = useAlertsContext();

  const toTitleCase = (str) =>
    str
      .trim()
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  const dashboardTypeToTitleCase = toTitleCase(item.alert_dashboard);

  const getSendToString = (sendTo) => {
    return sendTo.slack ? 'Slack' : '';
  };

  const hostnamesArray = item.hostnames
    .split(',')
    .map((host) => host.trim())
    .filter((host) => host.length > 0);

  const hostnamesDisplay =
    hostnamesArray.length > 1 ? (
      <Tooltip title={hostnamesArray.join(', ')} placement="top">
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap="3px">
          {hostnamesArray[0]} <Chip label={`+${hostnamesArray.length - 1}`} size="small" />
        </Box>
      </Tooltip>
    ) : (
      hostnamesArray[0] || ''
    );

  const handleDeleteClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = (confirmDelete) => {
    setOpenConfirmationDialog(false);
    if (confirmDelete) {
      deleteAlert(item.alert_id);
    }
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row" width="25%">
          <Typography variant="body2">{item.alert_name}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: '40%' }}>
          <Typography variant="body2" component="div" color="text.secondary">
            {hostnamesDisplay}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ width: '10%' }}>
          <Typography variant="body2" color="text.secondary">
            {dashboardTypeToTitleCase}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ width: '10%' }}>
          <Typography variant="body2" color="text.secondary">
            {getSendToString(item.send_to)}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ width: '10%', whiteSpace: 'nowrap' }}>
          <IconButton
            aria-label="edit"
            size="medium"
            color="success"
            sx={{ mr: 1 }}
            onClick={() => handleAlertEdit(item.alert_id)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton aria-label="delete" size="medium" color="error" onClick={handleDeleteClick}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Dialog open={openConfirmationDialog} onClose={() => handleCloseConfirmationDialog(false)} maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <DeleteIcon sx={{ mr: 1 }} />
          <Typography variant="h6" component="div">
            Delete Alert
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ py: 4 }}>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete the following alert?
          </Typography>
          <Paper
            sx={{
              padding: 2,
              marginTop: 2,
              backgroundColor: 'background.paper',
              boxShadow: 2,
            }}>
            <Box display="flex" gap={1}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                <strong>Alert Name:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {item.alert_name}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                <strong>Dashboard:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {dashboardTypeToTitleCase}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                <strong>Send To:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {getSendToString(item.send_to)}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                <strong>Sites:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {hostnamesArray.join(', ')}
              </Typography>
            </Box>
          </Paper>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ px: 3, py: 2, justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={() => handleCloseConfirmationDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ backgroundColor: 'error.main', '&:hover': { backgroundColor: 'error.dark' } }}
            onClick={() => handleCloseConfirmationDialog(true)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default AlertRow;
