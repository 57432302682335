import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import BarChart from '../charts/BarChart';
import Kpi from './Kpi';
import LocalOnlyToggle from '../input/LocalOnlyToggle';
import { trackWidgetView } from '../../helpers/Analytics';
import ExportButton from '../input/ExportButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useProcessingData } from '../../contexts/ProcessingDataContext';

const EditorialProductivityKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setfetchingData] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [assetsPublished, setAssetsPublished] = useState(null);
  const [assetsPublishedDiff, setAssetsPublishedDiff] = useState(null);
  const [avgWordCount, setAvgWordCount] = useState(null);
  const [avgWordCountDiff, setAvgWordCountDiff] = useState(null);
  const [daysDiff, setDaysDiff] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [localOnly, setLocalOnly] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colors = theme.palette;
  const { updateProcessingStatusForKpi, updateProcessingStatusForDateKpi } = useProcessingData();

  const kpiNames = ['Assets Published', 'Avg Word Count'];

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const formatChartData = (data) => {
    // Check if data is an array before calling reduce
    if (!Array.isArray(data)) {
      console.warn('formatChartData: Expected an array but received', data);
      return {};
    }

    return data.reduce((acc, cur) => ({ ...acc, [cur.label]: cur.publish_count }), {});
  };

  const fetchData = () => {
    if (props.apiRequestOptions) {
      setLoadingData(true);
      setfetchingData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      if (localOnly) {
        oParams.append('localOnly', localOnly);
      }

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/productivity', oAxiosOptions).then(
        (response) => {
          kpiNames.forEach((kpiName) => {
            updateProcessingStatusForKpi(kpiName, response.data);
          });

          updateProcessingStatusForDateKpi('Productivity KPIs', response.data.all_dates);

          let assetsPublished = response.data.content_publish_count || 0;
          setAssetsPublished(assetsPublished);

          let assetsPublishedDiff = response.data.content_publish_count_diff_pct;
          setAssetsPublishedDiff(assetsPublishedDiff);

          let avgWordCount = Math.round(response.data.average_word_count) || 0;
          setAvgWordCount(avgWordCount);

          let avgWordCountDiff = response.data.average_word_count_diff_pct;
          setAvgWordCountDiff(avgWordCountDiff);

          let daysDiff = response.data.days_diff;
          setDaysDiff(daysDiff);

          setChartData(formatChartData(response.data.content_types));

          setfetchingData(false);
          setLoadingData(false);
          trackWidgetView({ name: 'Productivity KPIs', local: localOnly });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handleLocalChange = (event) => {
    setLocalOnly(event.target.checked);
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate, localOnly]);

  useEffect(() => {
    props.setLoadingEditorialProductivityKpis(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  return (
    <Box sx={{ maxWidth: { lg: '50vw' }, mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
        <LocalOnlyToggle onChange={handleLocalChange} disabled={fetchingData} />
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={fetchingData}
          onClick={handleMenuToggle}>
          <MoreVertOutlinedIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={() => setAnchorEl(null)}
          keepMounted
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiMenuItem-root': {
              gap: 1,
              paddingLeft: '12px',
              fontSize: '.9em',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
              color: colors.primary.light, // colors.secondary.light,
            },
          }}>
          <MenuItem sx={{ padding: '0 !important' }}>
            <ExportButton
              // size="small"
              variant="link"
              sx={{
                color: 'white',
                gap: 1,
                paddingLeft: '12px',
                fontSize: '.9em',
                fontWeight: 400,
              }}
              {...props}
              modal={true}
              startImmediateExport={true}
              // iconOnly={true}
              export={{
                api: '/api/v1/site/productivity',
                name: 'productivity',
                params: {
                  localOnly: localOnly,
                },
              }}
            />
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          display: { sm: 'grid' },
          gridTemplateColumns: '150px 1fr',
          gap: { xs: '2em', lg: '3em' },
        }}>
        <Box>
          <Box sx={{ display: 'grid', gap: { xs: 2, lg: 3 } }}>
            {!loadingData && Number.isInteger(assetsPublished) ? (
              <Kpi value={assetsPublished} label="Assets Published" diff={assetsPublishedDiff} period={daysDiff} />
            ) : (
              <Skeleton variant="rectangular" height={90} />
            )}

            {/* avg word count KPI */}
            {!loadingData && Number.isInteger(avgWordCount) ? (
              <Kpi value={avgWordCount} label="Avg Word Count" diff={avgWordCountDiff} period={daysDiff} />
            ) : (
              <Skeleton variant="rectangular" height={90} />
            )}
          </Box>
        </Box>
        <Box>
          {!loadingData && chartData ? (
            <BarChart data={chartData} type="horizontal"></BarChart>
          ) : (
            <Skeleton variant="rectangular" height={300} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditorialProductivityKpis;
