import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 250,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const Loading = (props) => {
  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      </ContentStyle>
    </Container>
  );
};

export default Loading;
