import { Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InboxIcon from '@mui/icons-material/Inbox';
import { useAlertsContext } from '../../../providers/AlertsProvider';

const EmptyAlerts = () => {
  const { handleModalOpen } = useAlertsContext();

  return (
    <Box sx={{ p: 3, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <InboxIcon sx={{ fontSize: 60, color: 'action.disabled' }} />
      <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 2 }}>
        No alerts set up
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleModalOpen}
        startIcon={<AddIcon />}
        sx={{ mt: 4, width: 'fit-content' }}>
        New Alert
      </Button>
    </Box>
  );
};

export default EmptyAlerts;
