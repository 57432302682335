import React, { createContext, useState, useContext } from 'react';

// Import the DASHBOARD_KPI_TYPES constant
import { DASHBOARD_KPI_TYPES } from '../constants';

const AlertKPIContext = createContext();

export const useKPI = () => useContext(AlertKPIContext);

export const KPIProvider = ({ children }) => {
  const [currentKPI, setCurrentKPI] = useState(null);

  const setKPI = (kpiName) => {
    if (!kpiName) {
      setCurrentKPI(null);
      return;
    }

    let dashboard = null;
    let endpoint = null;
    let type = null;

    // Search through the DASHBOARD_KPI_TYPES to find the matching KPI
    for (const dashboardType in DASHBOARD_KPI_TYPES) {
      for (const category in DASHBOARD_KPI_TYPES[dashboardType]) {
        if (category === kpiName) {
          const kpis = DASHBOARD_KPI_TYPES[dashboardType][category];
          const firstKPIKey = Object.keys(kpis)[0];
          const firstKPI = kpis[firstKPIKey];
          dashboard = dashboardType;
          endpoint = firstKPI.endpoint;
          type = firstKPI.type;
          break;
        } else if (DASHBOARD_KPI_TYPES[dashboardType][category][kpiName]) {
          dashboard = dashboardType;
          endpoint = DASHBOARD_KPI_TYPES[dashboardType][category][kpiName].endpoint;
          type = DASHBOARD_KPI_TYPES[dashboardType][category][kpiName].type;
          break;
        }
      }
      if (dashboard) break;
    }

    if (dashboard && endpoint && type) {
      setCurrentKPI({ kpiName, dashboard, endpoint, type });
    } else {
      console.error(`KPI "${kpiName}" not found in DASHBOARD_KPI_TYPES`);
      setCurrentKPI(null);
    }
  };

  const clearKPI = () => {
    setCurrentKPI(null);
  };

  return <AlertKPIContext.Provider value={{ currentKPI, setKPI, clearKPI }}>{children}</AlertKPIContext.Provider>;
};
