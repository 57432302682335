import { Box, Button, Typography } from '@mui/material';

const ErrorAlerts = ({ onRetry }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3} m={2}>
    <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mb: 2 }}>
      Error getting alerts. Please try again later.
    </Typography>
    <Button variant="outlined" color="error" onClick={onRetry} sx={{ width: 'fit-content' }}>
      Retry
    </Button>
  </Box>
);

export default ErrorAlerts;
