import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { KPIProvider } from './providers/AlertKPIProvider';
import { AlertsProvider } from './providers/AlertsProvider';
import { Auth0ProviderWithHistory } from './auth0-provider-with-history';
import { HelmetProvider } from 'react-helmet-async';
import { ProcessingDataProvider } from './contexts/ProcessingDataContext';
import CreateAlert from './components/alerts/index.tsx';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from './theme/index.js';

const App = () => {
  return (
    <BrowserRouter basename="/ui">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HelmetProvider>
          <Auth0ProviderWithHistory>
            <AlertsProvider>
              <ProcessingDataProvider>
                <KPIProvider>
                  <AppRoutes />
                  <CreateAlert />
                </KPIProvider>
              </ProcessingDataProvider>
            </AlertsProvider>
          </Auth0ProviderWithHistory>
        </HelmetProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
