import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RequireAuth } from './components/auth/RequireAuth';
import Index from './pages/Index';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import SlackOAuthCallback from './components/integrations/slack/SlackOAuthCallback';
import Editorial from './pages/Editorial';
import Subscriptions from './pages/Subscriptions';
import Revenue from './pages/Revenue';
import SignIn from './pages/SignIn';
import SlackRedirect from './components/settings/Integrations/Redirects/SlackRedirect';
import { initAnalytics } from './helpers/Analytics';

const AppRoutes = () => {
  initAnalytics();

  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Index />}>
          <Route index element={<Navigate to="/dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="settings" element={<Settings />}>
            <Route index element={<Navigate to="alerts" replace />} />
            <Route path="alerts" element={<Settings />} />
            <Route path="integrations" element={<Settings />} />
          </Route>
          <Route path="integrations">
            <Route path="slack" element={<SlackOAuthCallback />} />
          </Route>
          <Route path="editorial" element={<Editorial />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="revenue" element={<Revenue />} />
        </Route>
      </Route>
      <Route element={<Index />}>
        <Route path="/dashboard/:dashboardId" element={<Dashboard />} />
      </Route>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/slack/redirect" element={<SlackRedirect />} />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default AppRoutes;
