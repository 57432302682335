import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import Kpi from './Kpi';
import PieChart from '../charts/PieChart';
import { trackWidgetView } from '../../helpers/Analytics';

const GamRevenue = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [kpiData, setKpiData] = useState(null);
  const [fetchingKpiData, setfetchingKpiData] = useState(false);
  const [loadingKpis, setLoadingKpis] = useState(true);
  const [chartData, setChartData] = useState(null);

  const formatSource = (label) => {
    if (typeof label === 'string') {
      return label
        .toLowerCase()
        .replace(/_/g, ' ')
        .split(' ')
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    } else {
      return '';
    }
  };

  const formatChartData = (data) => {
    // Check if data is an array before calling reduce
    if (!Array.isArray(data)) {
      console.warn('formatChartData: Expected an array but received', data);
      return {};
    }

    // Calculate the sum of type_revenue
    let sum = data.reduce((acc, obj) => acc + obj.type_revenue, 0);

    // Ensure sum is greater than 0 to avoid division by 0 errors
    if (sum <= 0) {
      console.warn('formatChartData: Sum of type_revenue is 0 or negative, data:', data);
      return {};
    }

    // Calculate the percentage of each type_revenue and format the chart data
    return data.reduce((acc, cur) => {
      const formattedSource = formatSource(cur.type);
      const sourceLabel = formattedSource === '-' ? 'Open Bidding' : formattedSource;
      return { ...acc, [sourceLabel]: ((cur.type_revenue * 100) / sum).toFixed(0) };
    }, {});
  };

  const fetchKpiData = () => {
    if (props.apiRequestOptions) {
      setLoadingKpis(true);
      setfetchingKpiData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/revenue/gam_summary', oAxiosOptions).then(
        (response) => {
          setKpiData(response.data);
          setChartData(formatChartData(response.data.by_type));
          setfetchingKpiData(false);
          setLoadingKpis(false);
          trackWidgetView({ name: 'GAM Revenue' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingKpiData && props.domains) {
      fetchKpiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingGamRevenue(fetchingKpiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingKpiData]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm="auto" xl={12}>
        {!loadingKpis &&
        kpiData &&
        ['total_gam_revenue', 'total_gam_revenue_diff_pct', 'days_diff'].every((key) => key in kpiData) ? (
          <Kpi
            type="money"
            value={kpiData.total_gam_revenue}
            label="Total GAM Revenue"
            diff={kpiData.total_gam_revenue_diff_pct}
            period={kpiData.days_diff}
            tip="Revenue displayed is reflective of the rate entered in GAM"
          />
        ) : (
          <Skeleton variant="rectangular" height={110} />
        )}
      </Grid>
      <Grid item xs={12} sm xl={12}>
        {!loadingKpis && chartData ? (
          <Box sx={{ width: { sm: 250, md: 300, xl: '100%' }, minWidth: { xl: 150 }, ml: { sm: 4, xl: 0 } }}>
            <PieChart data={chartData} ar="4:3" height={250}></PieChart>
          </Box>
        ) : (
          <Skeleton variant="rectangular" height={200} sx={{ width: { sm: '100%', md: 300 } }} />
        )}
      </Grid>
    </Grid>
  );
};

export default GamRevenue;
