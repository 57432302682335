import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const SlackOAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const exchangeToken = useCallback(
    async (code) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          '/api/v1/integrations/slack_save_access_token',
          {
            slack_access_token: code,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.success) {
          // Redirect to a success page or dashboard
          navigate('/settings/integrations');
        } else {
          // Handle the case when the response indicates an error
          setError('Failed to save Slack access token');
          navigate('/error');
        }
      } catch (error) {
        console.error('Error exchanging token:', error);
        setError('An error occurred while exchanging the token');
        navigate('/error');
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, navigate]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    if (code) {
      exchangeToken(code);
    } else {
      console.error('No code found in URL query parameters');
      setError('Missing authorization code');
      navigate('/error');
      setLoading(false);
    }
  }, [location, exchangeToken, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Handling Slack OAuth...</h2>
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default SlackOAuthCallback;
