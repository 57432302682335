import React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  CircularProgress,
  Box,
  Tooltip,
  Alert,
  FormHelperText,
  Grid,
  IconButton,
  Collapse,
  Fade,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import SlackChannelSelect from './SlackChannelSelect';
import AlertTriggerConfiguration from './AlertTriggerConfiguration';
import { useTheme } from '@mui/material/styles';
import { useCreateAlertModalContext } from './CreateAlertModalContext.tsx';

const CreateAlertModal = () => {
  const theme = useTheme();

  const {
    sites,
    isSitesLoading,
    sitesError,
    slackChannels,
    slackStatus,
    integrationsStatus,
    isFormValid,
    isSubmitting,
    submissionError,
    openConfirmationDialog,
    setSearchQuery,
    currentKPI,
    editAlertId,
    editAlertData,
    isLoading,
    alertConfig,
    updateAlertConfig,
    handlers,
    handleSubmit,
    handleClose,
    handleCloseConfirmationDialog,
    open,
    sections,
    isSectionsLoading,
    sectionsError,
    searchQuery,
  } = useCreateAlertModalContext();

  if (!sites) {
    return null;
  }

  if (sitesError) {
    return <Typography color="error">Error grabbing site data</Typography>;
  }

  const isTrendingDashboard = alertConfig.alertDashboard === 'trending';

  return (
    <>
      <Dialog open={open} sx={{ '& .MuiDialog-paper': { minWidth: '700px', p: 3, background: 'rgb(33, 43, 54)' } }}>
        <DialogTitle sx={{ m: 0, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center" gap="7px">
            <Typography variant="h5" component="div" color="text.primary">
              {editAlertId ? 'Edit Alert' : 'Create Alert'}
            </Typography>
            {currentKPI && (
              <>
                <Typography variant="h5" component="span">
                  for
                </Typography>
                <Typography variant="h5" component="span" color="primary" fontWeight="bold">
                  {currentKPI.kpiName}
                </Typography>
              </>
            )}
            <Tooltip
              title={
                <div
                  style={{
                    margin: 0,
                    padding: '16px',
                    borderRadius: '8px',
                    backgroundColor: 'rgb(23, 33, 43)',
                    boxShadow: theme.shadows[4],
                    color: theme.palette.text.primary,
                    fontSize: '14px',
                  }}>
                  <div style={{ fontWeight: 'bold', marginBottom: '12px', color: theme.palette.primary.main }}>
                    Alert Schedule
                  </div>
                  <div style={{ marginBottom: '8px' }}>Alerts are processed automatically:</div>
                  <ul
                    style={{
                      listStyleType: 'disc',
                      marginTop: '8px',
                      paddingLeft: '24px',
                      marginBottom: '0',
                      color: theme.palette.text.secondary,
                    }}>
                    <li>Trending: every 10 minutes</li>
                    <li>Editorial: 11am CST daily</li>
                    <li>Subscriptions: 11am CST on the 2nd of each month</li>
                    <li>Revenue: 11am CST daily</li>
                  </ul>
                </div>
              }
              placement="right"
              componentsProps={{ tooltip: { sx: { padding: 0 } } }}
              sx={{
                '& .MuiTooltip-tooltip': {
                  maxWidth: '300px',
                  backgroundColor: theme.palette.background.paper,
                  border: 'none',
                  boxShadow: 'none',
                  padding: 0,
                  margin: 0,
                },
              }}>
              <IconButton size="small">
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '10px',
              backgroundColor: 'grey',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              backgroundColor: '#BDBDBD',
              minHeight: '24px',
              border: '3px solid transparent',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#9E9E9E',
            },
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto',
          }}>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 3 }}>
                Set up alerts to monitor key performance indicators and receive notifications when specific thresholds are
                met, allowing timely decisions and actions.
              </Typography>
              <Collapse in={!integrationsStatus.slack && !integrationsStatus.error} timeout={350} unmountOnExit>
                <Box>
                  {Object.keys((integrationsStatus.slack && !integrationsStatus.error) || integrationsStatus).length ===
                    0 && (
                    <Alert severity="info" sx={{ marginBottom: 3 }} icon={<CircularProgress size={20} />}>
                      <Box display="flex" alignItems="center" flexDirection="row" gap={1} overflow="hidden">
                        <Typography variant="body2" color="text.secondary">
                          Checking your integrations...
                        </Typography>
                      </Box>
                    </Alert>
                  )}

                  {integrationsStatus.slack === false && (
                    <Alert severity="info" sx={{ marginBottom: 3 }}>
                      <Typography variant="body2" color="text.secondary">
                        Looks like you don't have any integrations. You can set up a Slack integration in the{' '}
                        <a href="/ui/settings/integrations" style={{ color: 'white' }}>
                          Integrations
                        </a>{' '}
                        page.
                      </Typography>
                    </Alert>
                  )}

                  {integrationsStatus.slack === true && slackChannels && slackChannels.length === 0 && (
                    <Alert severity="info" sx={{ marginBottom: 3 }}>
                      <Typography variant="body2" color="text.secondary">
                        You don't have any Slack channels to send alerts to. You can create a new channel in Slack and
                        refresh this page to see it here.
                      </Typography>
                    </Alert>
                  )}
                </Box>
              </Collapse>

              {integrationsStatus.error && (
                <Alert severity="error" sx={{ marginBottom: 3 }}>
                  <Typography variant="body2" color="text.secondary">
                    <b>There was an error checking your integrations.</b> Please try again later.
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    You won't be able to create alerts until this is resolved.
                  </Typography>
                </Alert>
              )}
              {submissionError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {submissionError}
                </Alert>
              )}
              <form onSubmit={handleSubmit} noValidate>
                <Typography variant="h6" color="text.secondary" sx={{ marginBottom: 1 }}>
                  Alert Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={currentKPI ? 12 : 6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      name="alert_name"
                      label="Name"
                      type="text"
                      size="small"
                      fullWidth
                      value={alertConfig.alertName}
                      helperText="Give your alert a name to easily identify it."
                      onChange={(e) => updateAlertConfig({ alertName: e.target.value })}
                    />
                  </Grid>
                  {!currentKPI && (
                    <Grid item xs={6}>
                      <FormControl fullWidth size="small" margin="dense">
                        <InputLabel id="alert-dashboard-label">Dashboard</InputLabel>
                        <Select
                          name="alert_dashboard"
                          label="Dashboard"
                          labelId="alert-dashboard-label"
                          value={alertConfig.alertDashboard}
                          onChange={(e) => updateAlertConfig({ alertDashboard: e.target.value, selectedSites: [] })}
                          fullWidth>
                          <MenuItem value="trending">Trending</MenuItem>
                          <MenuItem value="editorial">Editorial</MenuItem>
                          <MenuItem value="subscriptions">Subscriptions</MenuItem>
                          <MenuItem value="revenue">Revenue</MenuItem>
                        </Select>
                        <FormHelperText>Select the dashboard you want to monitor.</FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {isTrendingDashboard ? (
                      <Autocomplete
                        size="small"
                        id="site-select-trending"
                        options={sites?.map((site) => site.hostname) || []}
                        value={alertConfig.selectedSites[0] || null}
                        onChange={(event, newValue) => {
                          updateAlertConfig({ selectedSites: newValue ? [newValue] : [] });
                        }}
                        onInputChange={(event, newInputValue) => {
                          setSearchQuery(newInputValue);
                        }}
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        noOptionsText={
                          searchQuery
                            ? isSitesLoading
                              ? 'Searching...'
                              : 'No websites found. Please try a different search.'
                            : 'Start typing to search for a website.'
                        }
                        getOptionDisabled={(option) => {
                          return !sites.find((site) => site.hostname === option);
                        }}
                        loading={isSitesLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Website"
                            helperText="Start typing to search and select a website to monitor."
                            placeholder="Search for a website..."
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isSitesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        multiple
                        limitTags={3}
                        size="small"
                        id="site-select-others"
                        options={sites?.map((site) => site.hostname) || []}
                        value={alertConfig.selectedSites}
                        onChange={(event, newValue) => {
                          updateAlertConfig({ selectedSites: newValue });
                        }}
                        onInputChange={(event, newInputValue) => {
                          setSearchQuery(newInputValue);
                        }}
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        noOptionsText={
                          searchQuery
                            ? isSitesLoading
                              ? 'Searching...'
                              : 'No websites found. Please try a different search.'
                            : 'Start typing to search for websites.'
                        }
                        getOptionDisabled={(option) => {
                          return !sites.find((site) => site.hostname === option);
                        }}
                        loading={isSitesLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Websites"
                            helperText="Start typing to search and select websites to monitor."
                            placeholder="Search for websites..."
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isSitesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>

                <AlertTriggerConfiguration
                  alertConfig={alertConfig}
                  handlers={handlers}
                  sections={sections}
                  isSectionsLoading={isSectionsLoading}
                  sectionsError={sectionsError}
                />

                <Collapse in={integrationsStatus.slack && !integrationsStatus.error} timeout={350} unmountOnExit>
                  <Box py={2}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                      Notification Settings
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" color="text.secondary" mr={1}>
                        Send alert to:
                      </Typography>
                      <Fade in={slackStatus === 'loading'}>
                        <Box display={slackStatus === 'loading' ? 'flex' : 'none'} alignItems="center">
                          <CircularProgress size={20} />
                        </Box>
                      </Fade>
                      <Collapse in={slackStatus === 'error'}>
                        <Box display={slackStatus === 'error' ? 'flex' : 'none'} alignItems="center">
                          <Alert severity="error">There was an error grabbing your slack channels.</Alert>
                        </Box>
                      </Collapse>
                      <Fade in={slackStatus === 'success' || slackStatus === 'loadingNext'}>
                        <Box
                          display={slackStatus === 'success' || slackStatus === 'loadingNext' ? 'flex' : 'none'}
                          alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={alertConfig.sendTo.slack}
                                onChange={(e) =>
                                  updateAlertConfig({
                                    sendTo: e.target.checked
                                      ? { ...alertConfig.sendTo, slack: true }
                                      : { ...alertConfig.sendTo, slack: false },
                                  })
                                }
                                name="send_to"
                                color="primary"
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                color={integrationsStatus.slack === false ? 'text.disabled' : 'text.primary'}>
                                Slack
                              </Typography>
                            }
                            disabled={integrationsStatus.slack === false}
                          />
                        </Box>
                      </Fade>
                    </Box>
                    {alertConfig.sendTo.slack && slackChannels && (
                      <Box ml={1} display="flex" flexDirection="row">
                        <SlackChannelSelect
                          disabled={integrationsStatus.slack === false}
                          value={alertConfig.sendTo.channel_id}
                          onChange={(channelId) =>
                            updateAlertConfig({ sendTo: { ...alertConfig.sendTo, channel_id: channelId } })
                          }
                          channels={slackChannels.channels}
                        />
                      </Box>
                    )}
                    {/* Repeat the structure for each service, e.g., email, SMS, etc. */}
                  </Box>
                </Collapse>
              </form>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={!isFormValid || isSubmitting}>
            {isSubmitting ? (editAlertData ? 'Updating...' : 'Creating...') : editAlertData ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
        {submissionError && submissionError.message && <Alert severity="error">{submissionError.message}</Alert>}
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => handleCloseConfirmationDialog(false)}
        sx={{ '& .MuiDialog-paper': { p: 2, background: 'rgb(33, 43, 54)' } }}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography variant="body1">You have unsaved changes. Are you sure you want to close the dialog?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmationDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => handleCloseConfirmationDialog(true)}>
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateAlertModal;
