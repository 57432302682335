import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { CircularProgress, Box, Typography, Button, Paper } from '@mui/material';

const SlackRedirect = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const effectRan = useRef(false);

  useEffect(() => {
    // Prevent the effect from running more than once (mostly for strict mode purposes)
    if (effectRan.current) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Get the access token
      getAccessTokenSilently()
        .then((accessToken) => {
          // Exchange the authorization code for an access token
          axios
            .post(
              'https://slack.com/api/oauth.v2.access',
              new URLSearchParams({
                code: code,
                client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
                client_secret: process.env.REACT_APP_SLACK_CLIENT_SECRET,
                redirect_uri: `${window.location.origin}/ui/slack/redirect`,
              }),
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            )
            .then((response) => {
              if (response.data.ok) {
                const slackAccessToken = response.data.access_token;
                // Send the access token to your server
                axios
                  .post(
                    '/api/v1/integrations/slack_save_access_token',
                    { slack_access_token: slackAccessToken },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  )
                  .then((response) => {
                    if (response.statusText === 'OK' && response.status === 200) {
                      // Slack connection successful
                      navigate('/settings/integrations');
                    } else {
                      // Handle error case
                      setError('Connection to Slack failed');
                    }
                  })
                  .catch((error) => {
                    console.error('Error connecting to Slack:', error);
                    setError(error.message);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              } else {
                // Handle error case
                setError('Failed to exchange authorization code for access token');
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.error('Error exchanging authorization code:', error);
              setError(error.message);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.error('Error getting access token:', error);
          setError(error.message);
          setIsLoading(false);
        });
    } else {
      // Handle case when code is not present in the URL
      setError(true);
      setIsLoading(false);
    }

    return () => {
      effectRan.current = true;
    };
  }, [navigate, getAccessTokenSilently]);

  const handleTryAgain = () => {
    navigate('/settings/integrations');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'background.default',
      }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
          minWidth: '30%',
          maxWidth: '50%',
          minHeight: 200,
          backgroundColor: 'background.paper',
          borderRadius: 3,
          m: 2,
        }}>
        {isLoading ? (
          <>
            <CircularProgress size={60} sx={{ color: 'primary.main' }} />
            <Typography variant="h6" sx={{ mt: 2, color: 'neutral.main' }}>
              Connecting to Slack...
            </Typography>
          </>
        ) : error ? (
          <>
            <Typography variant="h6" sx={{ color: 'error.main' }}>
              Connection to Slack failed
            </Typography>
            <Typography variant="caption" sx={{ mt: 1, opacity: 0.3 }}>
              {error}
            </Typography>
            <Button variant="contained" sx={{ mt: 3 }} onClick={handleTryAgain}>
              Go back
            </Button>
          </>
        ) : null}
      </Paper>
    </Box>
  );
};

export default SlackRedirect;
