import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import PieChart from './PieChart';

const SourcesChart = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [chartData, setChartData] = useState(null);
  const [fetchingChartData, setfetchingChartData] = useState(false);
  const [loadingChart, setLoadingChart] = useState(true);

  const formatSource = (label) => {
    if (typeof label === 'string') {
      if (label === '(direct)') {
        label = 'Direct';
      }
      if (label.endsWith('.com')) {
        return label;
      }
      return label.charAt(0).toUpperCase() + label.slice(1);
    } else {
      return '';
    }
  };

  const formatChartData = (data) => {
    // Check if data is an array before calling reduce
    if (!Array.isArray(data)) {
      console.warn('formatChartData: Expected an array but received', data);
      return {};
    }

    // Calculate the sum of page_views
    let sum = data.reduce((acc, obj) => acc + obj.page_views, 0);

    // Ensure sum is greater than 0 to avoid division by 0
    if (sum <= 0) {
      console.warn('formatChartData: Sum of page_views is 0 or negative, data:', data);
      return {};
    }

    // Calculate the percentage of each page_views and format the chart data
    return data.reduce(
      (acc, cur) => ({
        ...acc,
        [formatSource(cur.label)]: ((cur.page_views * 100) / sum).toFixed(0),
      }),
      {}
    );
  };

  const fetchChartData = () => {
    if (props.apiRequestOptions) {
      setLoadingChart(true);
      setfetchingChartData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
        pageSize: 8,
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/sources', oAxiosOptions).then(
        (response) => {
          setChartData(formatChartData(response.data.data));
          setfetchingChartData(false);
          setLoadingChart(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingChartData && props.domains) {
      fetchChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingSourcesChart(fetchingChartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingChartData]);

  return (
    <Box sx={{ maxWidth: { sm: '50vw' }, mx: 'auto' }}>
      {!loadingChart && chartData ? (
        <PieChart data={chartData} ar="4:3" height={250}></PieChart>
      ) : (
        <Skeleton variant="rectangular" height={250} />
      )}
    </Box>
  );
};

export default SourcesChart;
