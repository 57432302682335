export const PRODUCT_NAME = 'Data Insights';
export const REQUIRED_ORG = 'TownNews';
export const DASHBOARD_KPI_TYPES = {
  trending: {
    'Trending Metrics': {
      'On-Fire': {
        endpoint: 'active_users',
        type: 'on_fire',
      },
    },
  },
  editorial: {
    Overall: {
      Pageviews: {
        endpoint: 'page_visitor_summary',
        type: 'page_views',
      },
      'Unique Users': {
        endpoint: 'page_visitor_summary',
        type: 'users',
      },
    },
    Content: {
      'Article Pageviews': {
        endpoint: 'content_summary',
        type: 'article',
      },
      'Collection Pageviews': {
        endpoint: 'content_summary',
        type: 'collection',
      },
      'Video Pageviews': {
        endpoint: 'content_summary',
        type: 'video',
      },
    },
    Productivity: {
      'Assets Published': {
        endpoint: 'productivity',
        type: 'content_publish_count',
      },
      'Avg Word Count': {
        endpoint: 'productivity',
        type: 'average_word_count',
      },
    },
  },
  subscriptions: {
    'Subscription Revenue': {
      'Total Subscribers': {
        endpoint: 'revenue_summary',
        type: 'total_subscribers',
      },
      'New Subscribers': {
        endpoint: 'revenue_summary',
        type: 'new_subscribers',
      },
      'Total Transaction Revenue': {
        endpoint: 'revenue_summary',
        type: 'total_revenue',
      },
      'New Transaction Revenue': {
        endpoint: 'revenue_summary',
        type: 'new_revenue',
      },
    },
    'Industry KPIs': {
      'Meter Stop Rate': {
        endpoint: 'kpis',
        type: 'meter_stop_rate',
      },
      'Paid Stop Conversion Rate': {
        endpoint: 'kpis',
        type: 'paid_stop_conversion_rate',
      },
    },
  },
  revenue: {
    'Web Subscriptions': {
      'Total Revenue': {
        endpoint: 'subscription',
        type: 'total_subscription_revenue',
      },
      'New Subscriber Revenue': {
        endpoint: 'subscription',
        type: 'new_subscription_revenue',
      },
    },
    Advertising: {
      'Total GAM Revenue': {
        endpoint: 'gam_summary',
        type: 'total_gam_revenue',
      },
      'GAM eCPM': {
        endpoint: 'gam_ecpm',
        type: 'total_ecpm',
      },
      'GAM Viewability': {
        endpoint: 'gam_viewability',
        type: 'gam_viewability',
      },
      'GAM Impressions': {
        endpoint: 'gam_impressions',
        type: 'total_impressions',
      },
    },
    'Other Revenue Sources': {
      'Content Exchange': {
        endpoint: 'other_sources',
        type: 'content_exchange',
      },
      Recommendations: {
        endpoint: 'other_sources',
        type: 'recommendations',
      },
      'IQ AdOps Revenue': {
        endpoint: 'iq_adops',
        type: 'iq_adops',
      },
    },
  },
};

export const contentTypes = [
  { id: 'article', label: 'Articles' },
  { id: 'video', label: 'Videos' },
  { id: 'collection', label: 'Collections' },
  { id: 'image', label: 'Images' },
  { id: 'poll', label: 'Polls' },
  { id: 'html', label: 'HTML' },
  { id: 'page', label: 'E-edition' },
];

export const sources = [
  { id: '(direct)', label: 'Direct' },
  { id: 'on-site', label: 'On-site' },
  { id: 'google', label: 'Google' },
  { id: 'twitter', label: 'Twitter' },
  { id: 'facebook', label: 'Facebook' },
  { id: 'bing', label: 'Bing' },
  { id: 'reddit', label: 'Reddit' },
  { id: 'yahoo', label: 'Yahoo' },
  { id: 'email', label: 'Email' },
];

export const sections = ['/news', '/news/local', '/sports', '/opinion', '/entertainment', '/lifestyles'];
