import { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import { useCreateAlertModalContext } from './CreateAlertModalContext.tsx';

const SlackChannelSelect = ({ disabled, value, onChange }) => {
  const { slackChannels, fetchSlackChannels, hasMoreSlackChannels, nextSlackChannelsCursor } = useCreateAlertModalContext();
  const [selectedChannel, setSelectedChannel] = useState(null);

  useEffect(() => {
    const channel = slackChannels.find((channel) => channel.id === value);
    setSelectedChannel(channel || null);
  }, [value, slackChannels]);

  const handleChange = (event, newValue) => {
    setSelectedChannel(newValue);
    onChange(newValue ? newValue.id : '');
  };

  const handleScroll = (event) => {
    const listboxNode = event.target;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (position === listboxNode.scrollHeight && hasMoreSlackChannels) {
      fetchSlackChannels(nextSlackChannelsCursor);
    }
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      <TagIcon />
      <Autocomplete
        sx={{ width: '300px' }}
        options={slackChannels}
        getOptionLabel={(option) => `#${option.name}`}
        value={selectedChannel}
        onChange={handleChange}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} label="Slack Channel" size="small" />}
        ListboxProps={{
          onScroll: handleScroll,
          style: { maxHeight: 200 },
        }}
        loading={hasMoreSlackChannels}
        loadingText="Loading more channels..."
        noOptionsText="No channels available"
      />
    </Box>
  );
};

export default SlackChannelSelect;
