import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const AlertsContext = createContext();

export const useAlertsContext = () => {
  return useContext(AlertsContext);
};

export const AlertsProvider = ({ children }) => {
  const [alertsData, setAlertsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertLoading, setIsAlertLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editAlertId, setEditAlertId] = useState(null);
  const [editAlertData, setEditAlertData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalAlerts, setTotalAlerts] = useState(0);
  const { getAccessTokenSilently } = useAuth0();

  const fetchAlerts = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/v1/alerts/?t=${timestamp}&page=${page}&pageSize=${pageSize}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setAlertsData(response.data);
        setTotalAlerts(response.data[0].totalAlerts);
        setCurrentPage(page);
      } catch (err) {
        console.error('Error fetching alerts:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, pageSize]
  );

  useEffect(() => {
    fetchAlerts(1); // Fetch first page on initial load
  }, [fetchAlerts]);

  const loadMore = useCallback(() => {
    if (!isLoading && alertsData.length < totalAlerts) {
      fetchAlerts(currentPage + 1);
    }
  }, [isLoading, alertsData.length, totalAlerts, fetchAlerts, currentPage]);

  const fetchAlert = useCallback(
    async (alertId) => {
      setIsAlertLoading(true);
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/v1/alerts/${alertId}?t=${timestamp}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEditAlertData(response.data);
      } catch (err) {
        console.error('Error fetching alert:', err);
        setError(err);
      } finally {
        setIsAlertLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  const deleteAlert = useCallback(
    async (alertId) => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        await axios.delete(`/api/v1/alerts/${alertId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAlertsData((prevAlerts) => prevAlerts.filter((alert) => alert.alert_id !== alertId));
        setTotalAlerts((prevTotal) => prevTotal - 1);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  const updateAlert = useCallback(
    async (updatedAlert) => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.put(`/api/v1/alerts/${editAlertId}`, updatedAlert, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAlertsData((prevAlerts) => prevAlerts.map((alert) => (alert.alert_id === editAlertId ? response.data : alert)));
        setEditAlertId(null);
        setEditAlertData(null);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, editAlertId]
  );

  const handleAlertEdit = (alertId) => {
    setEditAlertId(alertId);
    setIsModalOpen(true);
    fetchAlert(alertId);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditAlertId(null);
    setEditAlertData(null);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to first page
    fetchAlerts(1); // Fetch first page with new page size
  };

  const alertsContextValue = {
    alerts: alertsData,
    isLoading,
    isAlertLoading,
    error,
    fetchAlerts,
    deleteAlert,
    updateAlert,
    editAlertId,
    editAlertData,
    handleAlertEdit,
    isModalOpen,
    setIsModalOpen,
    handleModalClose,
    handleModalOpen,
    currentPage,
    pageSize,
    totalAlerts,
    loadMore,
    handlePageSizeChange,
  };

  return <AlertsContext.Provider value={alertsContextValue}>{children}</AlertsContext.Provider>;
};

export default AlertsProvider;
