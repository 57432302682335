import React, { useState, useMemo, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/material/styles';
import CreateAlertButton from '../settings/Alerts/CreateAlertButton';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useProcessingData } from '../../contexts/ProcessingDataContext';

const Percentage = React.memo(({ diff, period, theme, align }) => {
  const posDiff = Math.sign(diff) === 1;
  const diffPercent = useMemo(() => {
    if (diff === undefined || isNaN(diff)) {
      return '—';
    }
    return new Intl.NumberFormat('default', {
      style: 'percent',
      signDisplay: 'never',
      maximumFractionDigits: 0,
    }).format(diff);
  }, [diff]);

  const priorPeriod = typeof period === 'number' ? `Prior ${period} days` : period;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: align }}>
      {diff !== undefined && !isNaN(diff) ? (
        <>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: posDiff ? theme.palette.success.main : theme.palette.error.main,
              fontWeight: 'bold',
            }}>
            {posDiff ? <KeyboardDoubleArrowUpIcon fontSize="1rem" /> : <KeyboardDoubleArrowDownIcon fontSize="1rem" />}
            {diffPercent}
          </Typography>
          <Typography variant="caption" color="text.secondary" noWrap sx={{ marginLeft: 1 }}>
            {priorPeriod}
          </Typography>
        </>
      ) : (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
          }}>
          <Box component="span" sx={{ marginRight: 1 }}>
            {diffPercent} %
          </Box>
          <Tooltip title="Differential statistics processing." placement="top" arrow enterTouchDelay={0}>
            <HelpOutlineIcon fontSize="1.1rem" sx={{ verticalAlign: 'middle' }} />
          </Tooltip>
        </Typography>
      )}
    </Box>
  );
});

const Kpi = ({ value, label, type, tip, diff, period, notation, maximumFractionDigits, borderBottom, align }) => {
  const theme = useTheme();
  const { processingComponents } = useProcessingData();
  const [isHovered, setIsHovered] = useState(false);
  const [layout, setLayout] = useState('column');
  const containerRef = useRef(null);

  const updateLayout = (width) => {
    setLayout(width > 350 ? 'row' : 'column');
  };

  const getFontSizeBasedOnValue = (value) => {
    const length = value.toString().length;
    if (length < 10) return '2.2rem';
    if (length < 12) return '2rem';
    if (length < 14) return '1.8rem';
    return '1.8rem';
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        updateLayout(entries[0].contentRect.width);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const formattedValue = useMemo(() => {
    let style = 'decimal';
    if (type === 'percent' || type === 'percentage') style = 'percent';
    if (type === 'revenue' || type === 'currency' || type === 'money') style = 'currency';

    let useNotation = notation || 'standard';
    let maxFractionDigits = maximumFractionDigits || 2;

    return new Intl.NumberFormat('en-US', {
      style,
      currency: 'USD',
      notation: useNotation,
      maximumFractionDigits: maxFractionDigits,
    }).format(value ?? 0);
  }, [value, type, notation, maximumFractionDigits]);

  const isProcessing = processingComponents.includes(label);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: borderBottom ? 3 : 0,
        mb: borderBottom ? 3 : 0,
        borderBottom: borderBottom ? `1px solid ${theme.palette.divider}` : '',
        position: 'relative',
        backgroundColor: 'rgb(23, 33, 43)',
        borderRadius: theme.spacing(1),
        boxShadow: isHovered ? theme.shadows[4] : theme.shadows[1],
        width: '100%',
        alignItems: layout === 'column' ? (align === 'center' ? 'center' : 'flex-start') : 'default',
        minHeight: layout === 'column' ? 165 : 55,
        transition: 'box-shadow 0.2s ease-in-out',
        '&:hover': {
          boxShadow: theme.shadows[4],
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Box
        sx={{
          padding: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
        }}>
        {isProcessing && (
          <Tooltip
            title="Data is still processing and not included in this figure."
            placement="top"
            arrow
            enterTouchDelay={0}>
            <Typography
              variant="h5"
              sx={{
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(2),
                color: '#FFFF00',
              }}>
              *
            </Typography>
          </Tooltip>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: layout,
            justifyContent: layout === 'row' ? 'space-between' : 'center',
            width: '100%',
            minHeight: 55,
            alignItems: layout === 'column' ? (align === 'center' ? 'center' : 'flex-start') : 'center',
            marginBottom: theme.spacing(1),
          }}>
          <Typography
            color="primary"
            component="div"
            sx={{ fontWeight: 'bold', fontSize: getFontSizeBasedOnValue(formattedValue), minHeight: '50px' }}>
            {formattedValue}
          </Typography>
          <Percentage diff={diff} period={period} theme={theme} align={align} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: layout === 'column' ? (align === 'center' ? 'center' : 'flex-start') : 'default',
              width: '100%',
            }}>
            <Typography
              variant="subtitle1"
              color="text.primary"
              noWrap
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 1,
                ml: layout === 'column' ? (align === 'center' ? '36px' : '0') : 'default',
              }}>
              {label}
              {tip && (
                <Tooltip title={tip} placement="top" arrow enterTouchDelay={0}>
                  <HelpOutlineIcon fontSize="1rem" sx={{ ml: 0.5, verticalAlign: 'middle' }} />
                </Tooltip>
              )}
            </Typography>
          </Box>
          <CreateAlertButton kpiName={label} isHovered={isHovered} onHoverChange={setIsHovered} />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Kpi);
