import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DASHBOARD_KPI_TYPES } from '../../constants';
import { toSnakeCase } from '../../utils/stringUtils';

const KpiTypeSelect = ({ disabled, value, onChange, dashboard }) => {
  const availableKpiTypes = DASHBOARD_KPI_TYPES[dashboard];

  if (!availableKpiTypes) {
    return null;
  }

  const kpiOptions = Object.entries(availableKpiTypes).flatMap(([subCategory, kpis]) => [
    { key: `${toSnakeCase(subCategory)}-header`, header: subCategory },
    ...Object.entries(kpis).map(([kpi, { type, endpoint }]) => ({
      key: toSnakeCase(kpi),
      label: kpi,
      type,
      endpoint,
    })),
  ]);

  const handleChange = (event) => {
    const selectedKey = event.target.value;
    const selectedKpi = kpiOptions.find((item) => item.key === selectedKey);
    onChange(selectedKpi);
  };

  return (
    <FormControl fullWidth sx={{ my: 1 }} size="small" disabled={disabled || !dashboard}>
      <InputLabel id="kpi-type-label">KPI</InputLabel>
      <Select name="type" label="KPI" labelId="kpi-type-label" value={value.key} onChange={handleChange} fullWidth>
        {kpiOptions.length > 0 ? (
          kpiOptions.map((item, index) => (
            <MenuItem key={index} value={item.key} disabled={!!item.header}>
              {item.header || item.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>Select a Dashboard to see KPIs</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default KpiTypeSelect;
