import React, { useState, useContext, createContext, useEffect, useCallback, useMemo } from 'react';

const ProcessingDataContext = createContext();

/**
 * Custom hook to consume processing data context.
 * Throws an error if not used within a corresponding Provider, ensuring proper access to context.
 * @returns The context object with access to processing state and utility functions.
 */

export const useProcessingData = () => {
  const context = useContext(ProcessingDataContext);
  if (!context) {
    throw new Error('useProcessingData must be used within a ProcessingDataProvider');
  }
  return context;
};

/**
 * Provides a context provider for data processing states and logic.
 * Manages a list of components undergoing processing, processing status, and dates missing per KPI.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - Child components that will have access to the context.
 * @returns The Provider component wrapping children with access to processing context.
 */

export const ProcessingDataProvider = ({ children }) => {
  const [processingComponents, setProcessingComponents] = useState([]);
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [missingDatesByKpi, setMissingDatesByKpi] = useState({});

  // Effect to update isDataProcessing based on the number of components being processed.
  useEffect(() => {
    setIsDataProcessing(processingComponents.length > 0);
  }, [processingComponents]);

  const addComponent = useCallback((kpiName) => {
    setProcessingComponents((prev) => [...new Set([...prev, kpiName])]);
  }, []);

  const removeComponent = useCallback((kpiName) => {
    setProcessingComponents((prev) => prev.filter((compName) => compName !== kpiName));
  }, []);

  const updateProcessingStatusForKpi = useCallback(
    (kpiName, responseData) => {
      const isProcessing = Object.keys(responseData).some(
        (key) => key.endsWith('_diff_pct') && responseData[key] === 'undefined'
      );

      if (isProcessing) {
        addComponent(kpiName);
      } else {
        removeComponent(kpiName);
      }
    },
    [addComponent, removeComponent]
  );

  // Finds missing dates within a provided array of dates, considering the current date.
  const findMissingDates = useCallback((datesArray) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Normalize the current date to start of the day

    const parsedDates = datesArray.map((dateStr) => new Date(dateStr + 'T00:00:00')).sort((a, b) => a - b);

    if (parsedDates.length === 0) {
      return []; // Return an empty array if there are no dates to process
    }

    const latestDateInArray = new Date(Math.max(...parsedDates));
    latestDateInArray.setHours(0, 0, 0, 0);

    let expectedDate = new Date(latestDateInArray);
    expectedDate.setDate(expectedDate.getDate() + 1); // Start from the day after the latest date in the array

    const expectedDates = [];
    const dayBeforeCurrentDate = new Date(currentDate);
    dayBeforeCurrentDate.setDate(dayBeforeCurrentDate.getDate() - 1); // Calculate the day before the current date

    while (expectedDate <= dayBeforeCurrentDate) {
      // Exclude the current date from the check
      expectedDates.push(`${expectedDate.getMonth() + 1}/${expectedDate.getDate()}`); // Store as MM/DD string
      expectedDate.setDate(expectedDate.getDate() + 1);
    }

    return expectedDates.filter((dateStr) => {
      const [month, day] = dateStr.split('/');
      const dateToCheck = new Date(currentDate.getFullYear(), month - 1, day);
      return !parsedDates.some((date) => date.getTime() === dateToCheck.getTime());
    });
  }, []);

  // Updates processing status and missing dates for KPIs based on date information.
  const updateProcessingStatusForDateKpi = useCallback(
    (kpiName, datesArray) => {
      const missingDates = findMissingDates(datesArray);

      if (missingDates.length > 0) {
        addComponent(kpiName);
        setMissingDatesByKpi((prev) => ({
          ...prev,
          [kpiName]: missingDates,
        }));
      } else {
        removeComponent(kpiName);
      }
    },
    [addComponent, removeComponent, findMissingDates]
  );

  const contextValue = useMemo(
    () => ({
      processingComponents,
      missingDatesByKpi,
      addComponent,
      removeComponent,
      updateProcessingStatusForKpi,
      updateProcessingStatusForDateKpi,
      isDataProcessing,
    }),
    [
      processingComponents,
      missingDatesByKpi,
      addComponent,
      removeComponent,
      updateProcessingStatusForKpi,
      updateProcessingStatusForDateKpi,
      isDataProcessing,
    ]
  );

  return <ProcessingDataContext.Provider value={contextValue}>{children}</ProcessingDataContext.Provider>;
};
