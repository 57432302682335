import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Box, Divider, Skeleton } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const StatusIndicator = ({ isConnected, error }) => {
  let color = 'grey';
  let text = 'Not Connected';

  if (error) {
    color = 'red';
    text = 'Error';
  } else if (isConnected) {
    color = 'green';
    text = 'Connected';
  }

  return (
    <Box display="flex" alignItems="center">
      <FiberManualRecordIcon style={{ marginRight: '8px', fontSize: '1.2rem', color }} />
      <Typography color="text.secondary">{text}</Typography>
    </Box>
  );
};

StatusIndicator.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

const IntegrationCardContent = ({ isConnected, description, ButtonComponent, error }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Typography variant="body2" sx={{ mb: 3 }}>
      {description}
    </Typography>
    {ButtonComponent && <ButtonComponent isConnected={isConnected} disabled={error} />}
  </Box>
);

IntegrationCardContent.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  ButtonComponent: PropTypes.elementType,
  error: PropTypes.bool,
};

const IntegrationCard = ({ title, isConnected, description, isLoading, ButtonComponent, onConnectionChange, error }) => {
  if (isLoading) {
    return (
      <Card sx={{ maxWidth: 350, minHeight: 210 }}>
        <Skeleton variant="rectangular" width="100%" height={210} />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        maxWidth: {
          lg: 400,
        },
        minHeight: 210,
        minWidth: 300,
      }}>
      <CardContent sx={{ m: 1 }}>
        <Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">{title}</Typography>
            <StatusIndicator isConnected={isConnected} error={error} />
          </Box>
          <Divider sx={{ my: 2 }} />
          <IntegrationCardContent
            isConnected={isConnected}
            description={description}
            ButtonComponent={() => (
              <ButtonComponent isConnected={isConnected} onConnectionChange={onConnectionChange} disabled={error} />
            )}
            error={error}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

IntegrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ButtonComponent: PropTypes.elementType,
  onConnectionChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default IntegrationCard;
