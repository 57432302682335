import React from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  LinearProgress,
  Box,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AlertRow from './AlertRow';
import { useAlertsContext } from '../../../providers/AlertsProvider';

const AlertsTable = () => {
  const {
    alerts,
    deleteAlert,
    handleModalOpen,
    isLoading,
    totalAlerts,
    currentPage,
    pageSize,
    fetchAlerts,
    handlePageSizeChange,
  } = useAlertsContext();

  const handleChangePage = (event, newPage) => {
    fetchAlerts(newPage + 1); // Add 1 because newPage is 0-indexed
  };

  const handleChangeRowsPerPage = (event) => {
    handlePageSizeChange(event);
  };

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
        background: 'rgb(33, 43, 54)',
        position: 'relative', // For positioning the LinearProgress
      }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleModalOpen}
        startIcon={<AddIcon />}
        sx={{ mb: 2, width: 'fit-content' }}>
        New Alert
      </Button>

      {isLoading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}

      {alerts && alerts.length > 0 ? (
        <>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: 'inherit' }}>Name</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: 'inherit' }}>
                  Websites
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', borderBottom: 'inherit' }}>
                  Dashboard
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', borderBottom: 'inherit' }}>
                  Send to
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', borderBottom: 'inherit' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.map((alert) => (
                <AlertRow key={alert.alert_id} item={alert} deleteAlert={deleteAlert} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalAlerts}
            page={currentPage - 1} // Subtract 1 for MUI's 0-based index
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage="Alerts per page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
          />
        </>
      ) : isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <Typography>Loading alerts...</Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <Typography>No alerts found.</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default AlertsTable;
