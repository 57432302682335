import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingAlerts = () => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3} minHeight="200px">
    <CircularProgress size={50} />
    <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
      Loading Alerts...
    </Typography>
  </Box>
);

export default LoadingAlerts;
