import React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useProcessingData } from '../../contexts/ProcessingDataContext';

const DataUnavailableModal = ({ open, handleClose }) => {
  const { processingComponents, missingDatesByKpi } = useProcessingData();

  // Function to format missing dates as a single string
  const formatMissingDates = (dates) => {
    return dates.join(', ');
  };

  const transformComponentName = (name) => {
    const nameMappings = {
      'Article Pageviews': 'Article Pageviews KPI',
      'Unique Users': 'Overall Unique User KPI',
      'Collection Pageviews': 'Collection Pageviews KPI',
      'Video Pageviews': 'Video Pageviews KPI',
      Pageviews: 'Overall Pageviews KPI',
      'Top Content Producers': 'Top Content Producers Table',
      'Assets Published': 'Assets Published KPI',
      'Avg Word Count': 'Average Word Count KPI',
      // Add other mappings as necessary
    };

    // If the name exists in the mapping, return the transformed name, otherwise return the original name
    return nameMappings[name] || name;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">Data Unavailable</Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Data is still processing and is not included in the following charts:
        </Typography>
        <List>
          {processingComponents.map((componentName, index) => {
            // Transform the component name using the defined function
            const transformedName = transformComponentName(componentName);
            const missingDates = missingDatesByKpi[componentName];
            return (
              <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start', paddingY: 1 }}>
                <ListItemText
                  primary={`• ${transformedName}`}
                  primaryTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
                />
                {missingDates && (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} fontSize="small" />
                    <Typography variant="body2" color="text.secondary">
                      Missing dates: {formatMissingDates(missingDates)}
                    </Typography>
                  </Box>
                )}
              </ListItem>
            );
          })}
        </List>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Data from the previous day is processed overnight and generally available mid morning the next day.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default DataUnavailableModal;
