import { useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import ArticleIcon from '@mui/icons-material/Article';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { PRODUCT_NAME } from '../constants';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Chip from '@mui/material/Chip';
import InviteModal from './modals/InviteModal';
import SettingsIcon from '@mui/icons-material/Settings';

const Navbar = (props) => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  const { isAuthenticated } = useAuth0();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const canViewTrending = props.permissions && props.permissions.trending;
  const canViewEditorial = props.permissions && props.permissions.editorial;
  const canViewSubscriptions = props.permissions && props.permissions.subscription;
  const canViewRevenue = props.permissions && props.permissions.revenue;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ mx: 2, my: 4 }}>
        <SpeedIcon />
        <Typography variant="h3">{PRODUCT_NAME}</Typography>
      </Stack>
      <Divider />
      {isAuthenticated && (
        <>
          <List>
            {canViewTrending && (
              <ListItemButton component={NavLink} to="/dashboard">
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primary="Trending" />
              </ListItemButton>
            )}
            {canViewEditorial && (
              <ListItemButton component={NavLink} to="/editorial">
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Editorial" />
              </ListItemButton>
            )}
            {canViewSubscriptions && (
              <ListItemButton component={NavLink} to="/subscriptions">
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Subscriptions" />
              </ListItemButton>
            )}
            {canViewRevenue && (
              <ListItemButton component={NavLink} to="/revenue">
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      Revenue <Chip label="Beta" size="small" />
                    </>
                  }
                />
              </ListItemButton>
            )}
          </List>
          <Divider />
          <Box pt="10px">
            <ListItemButton component={NavLink} to="/settings">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </Box>
          <Box sx={{ mx: 1, my: 3, textAlign: 'center' }}>
            <Button variant="contained" startIcon={<GroupAddIcon />} onClick={() => setInviteModalOpen(true)}>
              Send Invitations
            </Button>
          </Box>
          <InviteModal handleClose={() => setInviteModalOpen(false)} open={inviteModalOpen} />
        </>
      )}

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: 'center', py: 3 }}>
        <Typography variant="body2" color={theme.palette.grey[500]}>
          &copy; {currentYear}
        </Typography>
      </Box>
    </Box>
  );
};

export default Navbar;
