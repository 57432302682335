import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircleIcon from '@mui/icons-material/Circle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import TuneIcon from '@mui/icons-material/Tune';
import { useInterval, useUpdateEffect } from 'usehooks-ts';
import Head from '../components/Head';
import DashboardToolbar from '../components/toolbars/DashboardToolbar';
import TrendingToolbar from '../components/toolbars/TrendingToolbar';
import TrendingGrid from '../components/grids/TrendingGrid';
import PageviewsChart from '../components/charts/PageviewsChart';
import ReferralsChart from '../components/charts/ReferralsChart';
import ShareModal from '../components/modals/ShareModal';
import OverallFiltersModal from '../components/modals/OverallFiltersModal';
import { useOutletContext } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { trackWidgetView } from '../helpers/Analytics';
import './Dashboard.css';

const Dashboard = (props) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const emptyChart = { labels: [], datasets: [] };

  const { drawerOpen, setDrawerOpen } = useOutletContext();
  const { apiRequestOptions } = useOutletContext();
  const { userDefaultFilter, setUserDefaultFilter } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const { setDisplayError } = useOutletContext();
  const { domains, setDomains } = useOutletContext();
  const { section, setSection } = useOutletContext();
  const { author, setAuthor } = useOutletContext();
  const { contentTypes, setContentTypes } = useOutletContext();
  const { source, setSource } = useOutletContext();
  const { platform, setPlatform } = useOutletContext();

  const [isTrendingFullscreen, setTrendingFullscreen] = useState(false);
  const [isOverviewFullscreen, setOverviewFullscreen] = useState(false);
  const [isLiveUpdating, setIsLiveUpdating] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [pageviewsChartData, setPageviewsChartData] = useState(emptyChart);
  const [referralsChartData, setReferralsChartData] = useState(emptyChart);
  const [loadingTable, setLoadingTable] = useState(true);
  const [fetchingTableData, setFetchingTableData] = useState(true);
  const [timeUntilNextUpdate, setTimeUntilNextUpdate] = useState(0);
  const [loadingPageviewsChart, setLoadingPageviewsChart] = useState(true);
  const [loadingReferralsChart, setLoadingReferralsChart] = useState(true);

  const [overallFiltersOpen, setOverallFiltersOpen] = useState(false);
  const [overallFilterCount, setOverallFilterCount] = useState(0);
  const handleOverallFiltersOpen = () => setOverallFiltersOpen(true);
  const handleOverallFiltersClose = () => setOverallFiltersOpen(false);
  const [overallSection, setOverallSection] = useState('');
  const [overallContentTypes, setOverallContentTypes] = useState([]);

  const [shareOpen, setShareOpen] = useState(false);
  const handleShareOpen = () => setShareOpen(true);
  const handleShareClose = () => setShareOpen(false);

  const { columnVisibility, setColumnVisibility } = useOutletContext();
  const { columns, setColumns } = useOutletContext();
  const { userChangedColumnVisibility, setUserChangedColumnVisibility } = useOutletContext();

  const brandColors = new Map([
    ['google', '#ea4335'],
    ['twitter', '#1da1f2'],
    ['facebook', '#1877f2'],
    ['bing', '#ffb900'],
    ['yahoo', '#410093'],
    ['reddit', '#ff4500'],
    ['on-site', '#47576A'],
    ['unknown', '#47576A'],
    ['(direct)', '#008844'],
    ['email', '#00C563'],
    ['other', '#ea4c89'],
  ]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const clearData = () => {
    setTableData([]);
    setPageviewsChartData(emptyChart);
    setReferralsChartData(emptyChart);
  };

  const setLoadingAll = (bool) => {
    bool = !!bool;
    setLoadingTable(bool);
    setLoadingPageviewsChart(bool);
    setLoadingReferralsChart(bool);
  };

  const handleDomainChange = (event, newValue) => {
    setDomains(newValue);
  };

  const handleSectionChange = (newValue) => {
    if (!newValue) {
      newValue = '';
    }
    setCurrentPage(1);
    setSection(newValue);
  };

  const handleAuthorChange = (newValue) => {
    setAuthor(newValue);
  };

  const handleContentTypeChange = (newValue) => {
    setCurrentPage(1);
    setContentTypes(newValue);
  };

  const handleSourceChange = (newValue) => {
    if (!newValue) {
      newValue = '';
    }
    setCurrentPage(1);
    setSource(newValue);
  };

  const handlePlatformChange = (newValue) => {
    setPlatform(newValue);
  };

  const handleSearchTermChange = (newValue) => {
    setCurrentPage(1);
    setSearchTerm(newValue);
  };

  const handleUserDefaultFilterChange = (filterConfig) => {
    setUserDefaultFilter(filterConfig);
  };

  const handlePageChange = (newPage) => {
    setIsLiveUpdating(false);
    setCurrentPage(newPage);
  };

  const formatHour = (val) => {
    var date = new Date(val);
    var localHour = date.toLocaleString('en', { hour: 'numeric', hour12: true });
    return localHour;
  };

  const formatSource = (item) => {
    let source = item.source;
    if (source === '(direct)') {
      source = 'Direct';
    }
    return source.charAt(0).toUpperCase() + source.slice(1);
  };

  const sourceColors = (data) => {
    let colors = [];
    data.forEach((data) => {
      if (brandColors.has(data.source)) {
        colors.push(brandColors.get(data.source));
      } else {
        colors.push(theme.palette.chart.orange.main);
      }
    });
    return colors;
  };

  const toggleTrendingFullscreen = () => {
    setTrendingFullscreen(!isTrendingFullscreen);
    document.body.style.overflow = !isTrendingFullscreen ? 'hidden' : '';

    //force redraw to ensure all user-selected columns are properly visible
    if (!isTrendingFullscreen) {
      setLoadingTable(true);
      setTimeout(() => setLoadingTable(false), 50);
    }
  };

  const toggleOverviewFullscreen = () => {
    setOverviewFullscreen(!isOverviewFullscreen);
    document.body.style.overflow = !isOverviewFullscreen ? 'hidden' : '';
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const toggleLiveUpdates = () => {
    if (!isLiveUpdating && currentPage !== 1) {
      setCurrentPage(1);
    }
    setIsLiveUpdating(!isLiveUpdating);
  };

  const handleOverallSectionChange = (newValue) => {
    if (!newValue) {
      newValue = '';
    }
    setOverallSection(newValue);
  };

  const handleOverallContentTypeChange = (newValue) => {
    setOverallContentTypes(newValue);
  };

  const applyOverallFilters = (newSection, newContentTypes) => {
    handleOverallSectionChange(newSection);
    handleOverallContentTypeChange(newContentTypes);
  };

  const updateOverallFilterCount = () => {
    let count = 0;
    if (overallSection !== '') {
      count++;
    }
    if (Array.isArray(overallContentTypes) && overallContentTypes.length) {
      count++;
    }
    setOverallFilterCount(count);
  };

  const fetchTrending = (isAutoUpdate = false) => {
    if (apiRequestOptions) {
      setFetchingTableData(true);

      // Check if currentPage is a valid number
      const currentPageNumber = typeof currentPage === 'number' && !isNaN(currentPage) ? currentPage : 1;

      var oParams = new URLSearchParams({
        page: currentPageNumber,
        pageSize: 10,
        sort: 'users',
        time_range: 30,
        hostname: domains,
        content_group: section || '',
        content_type: contentTypes.map((item) => item.id).join(','),
        traffic_source: source || '',
        platform: platform || '',
      });

      if (searchTerm) {
        oParams.append('search', searchTerm);
      }

      if (author) {
        oParams.append('author_email', author);
      }

      var oAxiosOptions = {
        headers: apiRequestOptions.headers,
        params: oParams,
      };

      axios
        .get('/api/v1/active_users', oAxiosOptions)
        .then(
          (response) => {
            setTableData(response.data);
            setLoadingTable(false);
            trackWidgetView({
              name: 'Trending',
              filter: section,
              autoUpdate: isAutoUpdate,
              fullscreen: isTrendingFullscreen,
            });
          },
          (error) => {
            setDisplayError(error);
          }
        )
        .then(() => {
          setTimeUntilNextUpdate(20);
          setFetchingTableData(false);
        });
    }
  };

  const fetchCharts = (isAutoUpdate = false) => {
    fetchPageviewsChart({ isAutoUpdate: isAutoUpdate });
    fetchReferralsChart({ isAutoUpdate: isAutoUpdate });
  };

  const fetchPageviewsChart = (isAutoUpdate = false) => {
    if (apiRequestOptions) {
      var oPageviewsParams = new URLSearchParams({
        timezone: timezone,
        hostname: domains,
        content_group: overallSection || '',
        content_type: overallContentTypes ? overallContentTypes.map((item) => item.id).join(',') : '',
      });

      var oPageviewsOptions = {
        headers: apiRequestOptions.headers,
        params: oPageviewsParams,
      };

      axios
        .get('/api/v1/pageviews_by_hour', oPageviewsOptions)
        .then(
          (response) => {
            const data = response.data.data;
            if (Array.isArray(data)) {
              setPageviewsChartData({
                labels: data.map((item) => formatHour(item.utc_hour)),
                datasets: [
                  {
                    label: 'Pageviews',
                    data: data.map((item) => item.pageviews),
                    backgroundColor: theme.palette.chart.blue.main,
                  },
                ],
              });
            } else {
              console.error('Invalid response data format. Expected an array.');
            }
            setLoadingPageviewsChart(false);
          },
          (error) => {
            setDisplayError(error);
          }
        )
        .then(() => {
          trackWidgetView({ name: 'Overall Pageviews', autoUpdate: isAutoUpdate });
        });
    }
  };

  const fetchReferralsChart = (isAutoUpdate = false) => {
    if (apiRequestOptions) {
      var oReferralsParams = new URLSearchParams({
        hostname: domains,
        content_group: overallSection || '',
        content_type: overallContentTypes ? overallContentTypes.map((item) => item.id).join(',') : '',
      });

      var oReferralsOptions = {
        headers: apiRequestOptions.headers,
        params: oReferralsParams,
      };

      axios
        .get('/api/v1/pageviews_by_refer', oReferralsOptions)
        .then(
          (response) => {
            setReferralsChartData({
              labels: response.data.data.map((item) => formatSource(item)),
              datasets: [
                {
                  label: 'Pageviews by Referral',
                  data: response.data.data.map((item) => item.pageviews),
                  backgroundColor: sourceColors(response.data.data),
                },
              ],
            });
            setLoadingReferralsChart(false);
          },
          (error) => {
            setDisplayError(error);
          }
        )
        .then(() => {
          trackWidgetView({ name: 'Overall Traffic Source', autoUpdate: isAutoUpdate });
        });
    }
  };

  useEffect(() => {
    if (filtersReady) {
      setLoadingAll(true);
      clearData();
      fetchTrending();
      fetchCharts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (filtersReady) {
      setLoadingAll(true);
      clearData();
      fetchTrending();
      fetchCharts();
      if (!userChangedColumnVisibility && !userDefaultFilter.configuration.columnVisibility) {
        let temp = { ...columnVisibility };
        temp.hostname = domains && domains.length === 1 ? false : true;
        setColumnVisibility(temp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains]);

  // set columnVisibility for the first time
  useEffect(() => {
    if (columns !== undefined && columns !== null) {
      if (columnVisibility === undefined || columnVisibility === null) {
        let v = {};
        columns.forEach((e) => (v[e.field] = e.checked));
        setColumnVisibility(v);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    if (filtersReady && !loadingTable) {
      setLoadingTable(true);
      setTableData([]);
      fetchTrending();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, author, contentTypes, source, platform, searchTerm, currentPage]);

  useEffect(() => {
    if (filtersReady && !loadingPageviewsChart && !loadingReferralsChart) {
      updateOverallFilterCount();
      setLoadingPageviewsChart(true);
      setLoadingReferralsChart(true);
      fetchCharts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallSection, overallContentTypes]);

  useInterval(() => {
    if (timeUntilNextUpdate > 0) {
      setTimeUntilNextUpdate(timeUntilNextUpdate - 1);
    } else if (isLiveUpdating && filtersReady && !fetchingTableData) {
      fetchTrending(true);
    }
  }, 1000);

  useInterval(() => {
    if (isLiveUpdating && filtersReady && !loadingPageviewsChart && !loadingReferralsChart) {
      fetchCharts(true);
    }
  }, 600000); // 10 minutes

  return (
    <>
      <Head title="Trending"></Head>
      <DashboardToolbar
        pageTitle="Trending"
        handleDrawerToggle={handleDrawerToggle}
        handleDomainChange={handleDomainChange}
        handleShareOpen={handleShareOpen}
        apiRequestOptions={apiRequestOptions}
        domains={domains}
        shareable={true}
        disabled={fetchingTableData || loadingPageviewsChart || loadingReferralsChart}
      />
      <Grid container spacing={3} sx={{ py: 3 }}>
        <Grid item xs={12} lg={12} xl={9}>
          <Paper
            elevation={0}
            className={isTrendingFullscreen ? 'is-fullscreen' : null}
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 2,
                    flexWrap: 'wrap',
                  }}>
                  <Typography variant="h2" sx={{ mr: 1 }}>
                    Last 30 minutes
                  </Typography>
                  <Tooltip
                    title={isLiveUpdating ? 'Live updates ON' : 'Live updates OFF'}
                    placement="top"
                    arrow
                    enterTouchDelay={0}>
                    <Chip
                      icon={isLiveUpdating ? <CircleIcon /> : <PauseCircleIcon />}
                      sx={{
                        '& .MuiChip-icon': {
                          color: isLiveUpdating ? theme.palette.error.main : theme.palette.neutral.o50,
                        },
                      }}
                      size="small"
                      label={isLiveUpdating ? (fetchingTableData ? 'Updating...' : 'Live') : 'Paused'}
                      onClick={toggleLiveUpdates}
                    />
                  </Tooltip>
                  <Box sx={{ flexGrow: 1 }} />
                  {isAuthenticated && (
                    <IconButton
                      aria-label="show-filters"
                      onClick={toggleShowFilters}
                      edge={'end'}
                      sx={{ display: { xs: 'flex', sm: 'none' }, ml: { xs: 0, sm: 2 } }}>
                      {showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm>
                <Collapse in={isMobile ? showFilters : true}>
                  <TrendingToolbar
                    isTrendingFullscreen={isTrendingFullscreen}
                    toggleTrendingFullscreen={toggleTrendingFullscreen}
                    handleSectionChange={handleSectionChange}
                    handleAuthorChange={handleAuthorChange}
                    handleContentTypeChange={handleContentTypeChange}
                    handleSourceChange={handleSourceChange}
                    handlePlatformChange={handlePlatformChange}
                    handleSearchTermChange={handleSearchTermChange}
                    selectedSection={section}
                    selectedAuthor={author}
                    selectedContentTypes={contentTypes}
                    selectedSource={source}
                    selectedPlatform={platform}
                    disabled={fetchingTableData}
                    columns={columns}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    setUserChangedColumnVisibility={setUserChangedColumnVisibility}
                  />
                </Collapse>
              </Grid>
            </Grid>
            <TrendingGrid
              tableData={tableData}
              loading={loadingTable}
              domains={domains}
              apiRequestOptions={apiRequestOptions}
              handlePageChange={handlePageChange}
              setColumns={setColumns}
              columns={columns}
              columnVisibility={columnVisibility}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12} xl={3}>
          <Paper
            elevation={0}
            className={isOverviewFullscreen ? 'is-fullscreen' : null}
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', mb: 2 }}>
              <Typography variant="h1" gutterBottom sx={{ mr: 1 }}>
                Overall
              </Typography>
              <div style={{ flexGrow: 1 }} />
              <Badge
                badgeContent={overallFilterCount}
                color="primary"
                overlap="circular"
                invisible={loadingPageviewsChart || loadingReferralsChart}>
                <IconButton
                  aria-label="filters"
                  onClick={handleOverallFiltersOpen}
                  disabled={loadingPageviewsChart || loadingReferralsChart}>
                  <TuneIcon />
                </IconButton>
              </Badge>
              <OverallFiltersModal
                filtersOpen={overallFiltersOpen}
                handleFilterssOpen={handleOverallFiltersOpen}
                handleFiltersClose={handleOverallFiltersClose}
                applyFilters={applyOverallFilters}
                selectedSection={overallSection}
                selectedContentTypes={overallContentTypes}
              />
              <IconButton
                aria-label="full-screen"
                onClick={toggleOverviewFullscreen}
                edge={'end'}
                sx={{ display: { xs: 'none', sm: 'flex' } }}>
                {isOverviewFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
            </Box>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} lg={7} xl={isOverviewFullscreen ? 7 : 12} className="pageviews-chart">
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'baseline', paddingBottom: 2 }}>
                  <Typography variant="h2" gutterBottom sx={{ mr: 1 }}>
                    Views
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    Last 24 hours
                  </Typography>
                </Box>
                <Box>
                  {loadingPageviewsChart ? (
                    <Skeleton variant="rectangular" height={250} />
                  ) : (
                    <Box sx={{ height: isOverviewFullscreen ? '60vh' : '250px' }}>
                      <PageviewsChart chartData={pageviewsChartData} />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={5} xl={isOverviewFullscreen ? 5 : 12} className="referrals-chart">
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                    justifyContent: 'space-between',
                    paddingBottom: 2,
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'baseline',
                    }}>
                    <Typography variant="h2" sx={{ mr: 1 }}>
                      Traffic Source
                      <Tooltip
                        title="This chart displays Last Click attribution within the last hour of traffic. First Click attribution across a period of time is available in the Editorial Dashboard."
                        placement="top"
                        arrow
                        enterTouchDelay={0}>
                        <HelpOutlineIcon sx={{ ml: 0.5, fontSize: '0.7em', verticalAlign: 'middle' }} />
                      </Tooltip>
                    </Typography>
                    <Typography variant="subtitle2">Last hour</Typography>
                  </Box>
                </Box>
                <Box>
                  {loadingReferralsChart ? (
                    <Skeleton variant="rectangular" height={360} />
                  ) : (
                    <Box>
                      <ReferralsChart chartData={referralsChartData} />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ShareModal
        shareOpen={shareOpen}
        handleShareOpen={handleShareOpen}
        handleShareClose={handleShareClose}
        domains={domains}
        section={section}
        author={author}
        contentTypes={contentTypes}
        source={source}
        apiRequestOptions={apiRequestOptions}
        userDefaultFilter={userDefaultFilter}
        handleUserDefaultFilterChange={handleUserDefaultFilterChange}
        columnVisibility={columnVisibility}
      />
    </>
  );
};

export default Dashboard;
