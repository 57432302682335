import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlertsContext } from '../../providers/AlertsProvider';
import { useKPI } from '../../providers/AlertKPIProvider';
import { useAlertConfig } from './useAlertConfig';
import { useDebounceValue } from 'usehooks-ts';
import { toSnakeCase } from '../../utils/stringUtils';
import { DASHBOARD_KPI_TYPES } from '../../constants/index';

interface Site {
  hostname: string;
}

interface SlackChannel {
  id: string;
  name: string;
}

interface CreateAlertModalContextProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

interface CreateAlertModalContextValue {
  sites: Site[];
  isSitesLoading: boolean;
  sitesError: Error | null;
  slackChannels: SlackChannel[] | null;
  slackStatus: 'idle' | 'loading' | 'success' | 'error' | 'loadingNext';
  integrationsStatus: Record<string, boolean>;
  isFormValid: boolean;
  isSubmitting: boolean;
  submissionError: Error | null;
  isFormDirty: boolean;
  openConfirmationDialog: boolean;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  currentKPI: any;
  clearKPI: () => void;
  editAlertId: string | null;
  editAlertData: any;
  isLoading: boolean;
  fetchAlerts: () => void;
  alertConfig: any;
  updateAlertConfig: (config: any) => void;
  handlers: any;
  fetchSlackChannels: () => Promise<void>;
  fetchIntegrationStatus: () => Promise<void>;
  handleCloseConfirmationDialog: (confirm: boolean) => void;
  handleClose: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  open: boolean;
  onClose: () => void;
  sections: any[];
  isSectionsLoading: boolean;
  sectionsError: Error | null;
  sectionSearchQuery: string;
  setSectionSearchQuery: (query: string) => void;
  debouncedSectionSearchQuery: string;
  hasMoreSlackChannels: boolean;
  nextSlackChannelsCursor: string | null;
}

const CreateAlertModalContext = createContext<CreateAlertModalContextValue | undefined>(undefined);

export const useCreateAlertModalContext = () => {
  const context = useContext(CreateAlertModalContext);
  if (!context) {
    throw new Error('useCreateAlertModalContext must be used within a CreateAlertModalProvider');
  }
  return context;
};

export const CreateAlertModalProvider: React.FC<CreateAlertModalContextProps> = ({ open, onClose, children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [slackChannels, setSlackChannels] = useState<SlackChannel[] | null>(null);
  const [slackStatus, setSlackStatus] = useState<'idle' | 'loading' | 'success' | 'error' | 'loadingNext'>('idle');
  const [nextSlackChannelsCursor, setNextSlackChannelsCursor] = useState<string | null>(null);
  const [integrationsStatus, setIntegrationsStatus] = useState<Record<string, boolean>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<Error | null>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [sections, setSections] = useState<any[]>([]);
  const [isSectionsLoading, setIsSectionsLoading] = useState(true);
  const [sectionsError, setSectionsError] = useState<Error | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounceValue(searchQuery, 300);
  const [sectionSearchQuery, setSectionSearchQuery] = useState('');
  const [debouncedSectionSearchQuery] = useDebounceValue(sectionSearchQuery, 300);
  const { sites, isLoading: isSitesLoading, error: sitesError } = useFetchSites(open ? debouncedSearchQuery : '');
  const { currentKPI, clearKPI } = useKPI();
  const { editAlertId, editAlertData, isAlertLoading, fetchAlerts } = useAlertsContext();
  const { alertConfig, updateAlertConfig, handlers } = useAlertConfig(editAlertData);

  // Fetch Slack channels
  const fetchSlackChannels = useCallback(
    async (cursor: string = '') => {
      setSlackStatus(cursor ? 'loadingNext' : 'loading');
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get('/api/v1/integrations/slack_get_channels', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            // private channels are for testing purposes only
            // types: 'private_channel',
            types: 'public_channel',
            pageSize: 100,
            cursor,
          },
        });

        if (!response.data.ok) {
          throw new Error(`Failed to fetch Slack channels: ${response.data.error}`);
        }

        const newChannels = response.data.channels;
        const nextCursor = response.data.response_metadata?.next_cursor;

        setSlackChannels((prevChannels) => (prevChannels ? [...prevChannels, ...newChannels] : newChannels));
        setNextSlackChannelsCursor(nextCursor);
        setSlackStatus('success');
      } catch (err) {
        console.error('Error fetching Slack channels:', err);
        setSlackStatus('error');
      }
    },
    [getAccessTokenSilently]
  );

  // Fetch integration status
  const fetchIntegrationStatus = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const timestamp = new Date().getTime(); // Use current timestamp to prevent caching
      const response = await axios.get(`/api/v1/integrations/?_=${timestamp}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setIntegrationsStatus(response.data);
    } catch (err) {
      console.error('Error fetching Slack integration status:', err);
      setIntegrationsStatus({
        slack: false,
        error: true,
      });
    }
  }, [getAccessTokenSilently]);

  // Handle closing the confirmation dialog
  const handleCloseConfirmationDialog = (confirm: boolean) => {
    setOpenConfirmationDialog(false);
    if (confirm) {
      clearKPI();
      searchQuery && setSearchQuery('');
      onClose();
    }
  };

  const isDirty = useMemo(() => {
    if (editAlertData) {
      return (
        alertConfig.alertName !== editAlertData.alert_name ||
        alertConfig.alertDashboard !== editAlertData.alert_dashboard ||
        alertConfig.selectedSites.join(',') !== editAlertData.hostnames ||
        (alertConfig.kpiType && alertConfig.kpiType.key !== toSnakeCase(editAlertData.alert_trigger?.kpi_label || '')) ||
        alertConfig.operator !== editAlertData.alert_trigger?.operator ||
        alertConfig.thresholdValue !== editAlertData.alert_trigger?.value ||
        alertConfig.timeRange !== editAlertData.alert_trigger?.range ||
        alertConfig.sendTo.channel_id !== editAlertData.send_to?.channel_id
      );
    } else {
      return (
        alertConfig.alertName.trim() !== '' ||
        alertConfig.alertDashboard.trim() !== '' ||
        alertConfig.selectedSites.length > 0 ||
        (alertConfig.kpiType && alertConfig.kpiType.key.trim() !== '') ||
        alertConfig.operator.trim() !== '' ||
        alertConfig.thresholdValue.trim() !== '' ||
        alertConfig.timeRange.trim() !== '' ||
        alertConfig.sendTo.channel_id.trim() !== ''
      );
    }
  }, [
    alertConfig.alertName,
    alertConfig.alertDashboard,
    alertConfig.selectedSites,
    alertConfig.kpiType,
    alertConfig.operator,
    alertConfig.thresholdValue,
    alertConfig.timeRange,
    alertConfig.sendTo.channel_id,
    editAlertData,
  ]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  // Handle closing the modal
  const handleClose = () => {
    if (editAlertData && !isDirty) {
      onClose();
    } else if (isFormDirty) {
      setOpenConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmissionError(null);

    const token = await getAccessTokenSilently();
    const requestData = {
      alert_name: alertConfig.alertName,
      alert_dashboard: alertConfig.alertDashboard,
      alert_endpoint: alertConfig.kpiType.endpoint,
      alert_trigger: {
        type:
          alertConfig.alertDashboard === 'editorial' && alertConfig.kpiType.endpoint.startsWith('productivity')
            ? `${alertConfig.kpiType.type}${alertConfig.localOnly ? '|local' : '|all'}`
            : alertConfig.kpiType.type,
        operator: alertConfig.operator,
        value: alertConfig.thresholdValue,
        range: alertConfig.timeRange,
        kpi_label: alertConfig.kpiType.label,
      },
      send_to: alertConfig.sendTo,
      hostnames: alertConfig.selectedSites.join(','),
      trending_criteria: {
        section: alertConfig.trendingCriteria.section,
        author_email: alertConfig.trendingCriteria.author,
        content_type: alertConfig.trendingCriteria.contentType.map((type: any) => type.id),
        source: alertConfig.trendingCriteria.source,
      },
    };
    const requestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      if (editAlertId) {
        // Update the existing alert
        await axios.put(`/api/v1/alerts/${editAlertId}`, requestData, requestConfig);
      } else {
        // Create a new alert
        await axios.post('/api/v1/alerts/', requestData, requestConfig);
      }
      onClose();
      clearKPI();
      fetchAlerts();
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmissionError(new Error('Failed to save the alert. Please try again.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fetch Slack channels and integration status when the modal opens
  useEffect(() => {
    if (open) {
      fetchIntegrationStatus();
      integrationsStatus.slack && fetchSlackChannels('');
    }
  }, [open, fetchSlackChannels, fetchIntegrationStatus, integrationsStatus.slack]);

  // Validate the form
  useEffect(() => {
    const hasIntegrationErrors = Object.keys(integrationsStatus).length === 0 || !integrationsStatus.slack;

    const isValid =
      alertConfig.alertName.trim() !== '' &&
      alertConfig.alertDashboard.trim() !== '' &&
      alertConfig.selectedSites.length > 0 &&
      alertConfig.sendTo.slack === true &&
      alertConfig.sendTo.channel_id.trim() !== '' &&
      !hasIntegrationErrors &&
      (currentKPI ||
        alertConfig.alertDashboard === 'trending' ||
        (alertConfig.alertDashboard !== 'trending' &&
          alertConfig.kpiType &&
          alertConfig.kpiType.key.trim() !== '' &&
          alertConfig.operator.trim() !== '' &&
          alertConfig.thresholdValue.trim() !== '' &&
          alertConfig.timeRange.trim() !== ''));

    setIsFormValid(isValid);
  }, [
    alertConfig.alertDashboard,
    alertConfig.alertName,
    alertConfig.kpiType,
    alertConfig.selectedSites,
    alertConfig.operator,
    alertConfig.thresholdValue,
    alertConfig.timeRange,
    alertConfig.sendTo,
    currentKPI,
    integrationsStatus,
  ]);

  useEffect(() => {
    if (open && editAlertData) {
      const updatedAlertConfig = {
        alertName: editAlertData.alert_name || '',
        alertDashboard: editAlertData.alert_dashboard || '',
        selectedSites: editAlertData.hostnames.split(',') || [],
        operator: editAlertData.alert_trigger?.operator || '',
        thresholdValue: editAlertData.alert_trigger?.value || '',
        timeRange: editAlertData.alert_trigger?.range || '',
        sendTo: {
          slack: editAlertData.send_to?.slack || false,
          channel_id: editAlertData.send_to?.channel_id || '',
        },
        kpiType: { key: '', endpoint: '', type: '', label: '' },
      };
      // Set the KPI directly from the editAlertData for other dashboards
      const kpiName = editAlertData.alert_trigger?.kpi_label;
      if (kpiName) {
        let dashboard: string | null = null;
        let endpoint = null;
        let type = null;

        // Search through the DASHBOARD_KPI_TYPES to find the matching KPI
        for (const dashboardType in DASHBOARD_KPI_TYPES) {
          for (const category in DASHBOARD_KPI_TYPES[dashboardType]) {
            if (category === kpiName) {
              const kpis = DASHBOARD_KPI_TYPES[dashboardType][category];
              const firstKPIKey = Object.keys(kpis)[0];
              const firstKPI = kpis[firstKPIKey];
              dashboard = dashboardType;
              endpoint = firstKPI.endpoint;
              type = firstKPI.type;
              break;
            } else if (DASHBOARD_KPI_TYPES[dashboardType][category][kpiName]) {
              dashboard = dashboardType;
              endpoint = DASHBOARD_KPI_TYPES[dashboardType][category][kpiName].endpoint;
              type = DASHBOARD_KPI_TYPES[dashboardType][category][kpiName].type;
              break;
            }
          }
          if (dashboard) break;
        }

        if (dashboard && endpoint && type) {
          updatedAlertConfig.kpiType = {
            key: toSnakeCase(kpiName),
            label: kpiName,
            endpoint,
            type,
          };
        } else {
          console.error(`KPI "${kpiName}" not found in DASHBOARD_KPI_TYPES`);
        }
      }
      updateAlertConfig(updatedAlertConfig);
    }
  }, [open, editAlertData, updateAlertConfig]);

  useEffect(() => {
    if (!open) {
      // Reset form state when the modal is closed
      updateAlertConfig({
        alertName: '',
        alertDashboard: '',
        selectedSites: [],
        kpiType: { key: '', endpoint: '', type: '', label: '' },
        operator: '',
        thresholdValue: '',
        timeRange: '',
        sendTo: { slack: false, channel_id: '' },
      });
      setSlackStatus('idle');
      setIsFormValid(false);
      setIsSubmitting(false);
      setSubmissionError(null);
      setIsFormDirty(false);
      setOpenConfirmationDialog(false);
      setSearchQuery('');
      setSectionSearchQuery('');
      clearKPI();
      setIntegrationsStatus({});
    }
  }, [open, updateAlertConfig, clearKPI]);

  const fetchSections = useCallback(
    async (searchQuery: string) => {
      setIsSectionsLoading(true);
      setSectionsError(null);

      if (alertConfig.selectedSites?.length === 0) {
        setSections([]);
        setIsSectionsLoading(false);
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get('/api/v1/site/sections', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            hostname: alertConfig.selectedSites[0],
            search: searchQuery,
          },
        });

        setSections(response.data);
      } catch (err) {
        setSectionsError(err as Error);
      } finally {
        setIsSectionsLoading(false);
      }
    },
    [getAccessTokenSilently, alertConfig.selectedSites]
  );

  useEffect(() => {
    if (alertConfig.alertDashboard === 'trending' && open) {
      fetchSections(debouncedSectionSearchQuery);
    }
  }, [
    getAccessTokenSilently,
    alertConfig.selectedSites,
    debouncedSectionSearchQuery,
    fetchSections,
    open,
    alertConfig.alertDashboard,
  ]);

  const contextValue: CreateAlertModalContextValue = {
    sites,
    isSitesLoading,
    sitesError,
    slackChannels,
    slackStatus,
    integrationsStatus,
    isFormValid,
    isSubmitting,
    submissionError,
    isFormDirty,
    openConfirmationDialog,
    searchQuery,
    setSearchQuery,
    currentKPI,
    clearKPI,
    editAlertId,
    editAlertData,
    isLoading: isAlertLoading,
    fetchAlerts,
    alertConfig,
    updateAlertConfig,
    handlers,
    fetchSlackChannels,
    fetchIntegrationStatus,
    handleCloseConfirmationDialog,
    handleClose,
    handleSubmit,
    open,
    onClose,
    sections,
    isSectionsLoading,
    sectionsError,
    sectionSearchQuery,
    setSectionSearchQuery,
    debouncedSectionSearchQuery,
    hasMoreSlackChannels: !!nextSlackChannelsCursor,
    nextSlackChannelsCursor,
  };

  return <CreateAlertModalContext.Provider value={contextValue}>{children}</CreateAlertModalContext.Provider>;
};

const useFetchSites = (searchQuery: string) => {
  const [sites, setSites] = useState<Site[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { isModalOpen } = useAlertsContext();

  useEffect(() => {
    if (!isModalOpen) {
      return () => {
        setSites([]);
        setIsLoading(false);
        setError(null);
      };
    }

    const fetchSites = async () => {
      if (!searchQuery) {
        setSites([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        const timestamp = Date.now();
        const response = await axios.get(`/api/v1/list_sites?_=${timestamp}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { search: searchQuery },
        });

        setSites(response.data.data);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSites();
  }, [getAccessTokenSilently, searchQuery, isModalOpen]);

  return { sites, isLoading, error };
};
