import { useState, useCallback, useEffect, useMemo } from 'react';
import { useKPI } from '../../providers/AlertKPIProvider';

export const useAlertConfig = (editAlertData) => {
  const { currentKPI } = useKPI();

  const initialAlertConfig = useMemo(
    () => ({
      alertName: '',
      alertDashboard: currentKPI?.dashboard || '',
      selectedSites: [],
      kpiType: currentKPI
        ? { key: '', endpoint: currentKPI.endpoint, type: currentKPI.type, label: currentKPI.kpiName }
        : { key: '', endpoint: '', type: '', label: '' },
      operator: '',
      thresholdValue: '',
      timeRange: '',
      sendTo: {
        slack: false,
        channel_id: '',
      },
      trendingCriteria: {
        section: '',
        author: '',
        contentType: [],
        source: '',
      },
      localOnly: false,
    }),
    [currentKPI]
  );

  const [alertConfig, setAlertConfig] = useState(initialAlertConfig);

  const updateAlertConfig = useCallback((updates) => {
    setAlertConfig((prevConfig) => {
      if (updates.alertDashboard === 'trending') {
        const newState = {
          ...prevConfig,
          ...updates,
          trendingCriteria: {
            section: updates.trendingCriteria?.section || prevConfig.trendingCriteria.section,
            author: updates.trendingCriteria?.author || prevConfig.trendingCriteria.author,
            contentType: updates.trendingCriteria?.contentType || prevConfig.trendingCriteria.contentType,
            source: updates.trendingCriteria?.source || prevConfig.trendingCriteria.source,
          },
        };
        return newState;
      } else {
        const newState = {
          ...prevConfig,
          ...updates,
        };
        return newState;
      }
    });
  }, []);

  useEffect(() => {
    updateAlertConfig({
      alertDashboard: currentKPI?.dashboard || '',
      kpiType: currentKPI
        ? { key: '', endpoint: currentKPI.endpoint, type: currentKPI.type, label: currentKPI.kpiName }
        : { key: '', endpoint: '', type: '', label: '' },
    });
  }, [currentKPI, updateAlertConfig]);

  useEffect(() => {
    if (!editAlertData && alertConfig.alertDashboard === 'trending') {
      setAlertConfig((prevConfig) => ({
        ...prevConfig,
        kpiType: { key: '', endpoint: 'active_users', type: 'on_fire', label: '' },
        operator: '',
        thresholdValue: '',
        timeRange: '',
      }));
    }
  }, [alertConfig.alertDashboard, editAlertData]);

  useEffect(() => {
    if (
      !editAlertData &&
      alertConfig.alertDashboard === 'trending' &&
      alertConfig.trendingCriteria &&
      alertConfig.trendingCriteria.contentType &&
      alertConfig.trendingCriteria.contentType.length === 0
    ) {
      setAlertConfig((prevConfig) => ({
        ...prevConfig,
        trendingCriteria: {
          ...prevConfig.trendingCriteria,
          contentType: [{ id: 'article', label: 'Articles' }],
        },
      }));
    }
  }, [alertConfig.alertDashboard, alertConfig.trendingCriteria, editAlertData]);

  const handleKpiTypeChange = useCallback(
    (selectedKpi) => {
      updateAlertConfig({
        kpiType: {
          key: selectedKpi.key,
          label: selectedKpi.label,
          type: selectedKpi.type,
          endpoint: selectedKpi.endpoint,
        },
        localOnly:
          selectedKpi.endpoint.startsWith('productivity') && alertConfig.alertDashboard === 'editorial'
            ? alertConfig.localOnly
            : false,
      });
    },
    [updateAlertConfig, alertConfig.alertDashboard, alertConfig.localOnly]
  );

  const handleOperatorChange = useCallback(
    (e) => {
      updateAlertConfig({ operator: e.target.value });
    },
    [updateAlertConfig]
  );

  const handleThresholdValueChange = useCallback(
    (e) => {
      updateAlertConfig({ thresholdValue: e.target.value });
    },
    [updateAlertConfig]
  );

  const handleTimeRangeChange = useCallback(
    (e) => {
      updateAlertConfig({ timeRange: e.target.value });
    },
    [updateAlertConfig]
  );

  const handleTrendingSectionChange = useCallback(
    (newValue) => {
      console.log('Selected section:', newValue);
      updateAlertConfig({
        trendingCriteria: {
          ...alertConfig.trendingCriteria,
          section: newValue ? newValue.id : '',
        },
      });
    },
    [updateAlertConfig, alertConfig.trendingCriteria]
  );

  const handleTrendingAuthorChange = useCallback(
    (e) => {
      updateAlertConfig({
        trendingCriteria: {
          ...alertConfig.trendingCriteria,
          author: e.target.value,
        },
      });
    },
    [updateAlertConfig, alertConfig.trendingCriteria]
  );

  const handleTrendingContentTypeChange = useCallback(
    (event, newValue) => {
      updateAlertConfig({
        trendingCriteria: {
          ...alertConfig.trendingCriteria,
          contentType: newValue,
        },
      });
    },
    [updateAlertConfig, alertConfig.trendingCriteria]
  );

  const handleTrendingSourceChange = useCallback(
    (event, newValue) => {
      updateAlertConfig({
        trendingCriteria: {
          ...alertConfig.trendingCriteria,
          source: newValue,
        },
      });
    },
    [updateAlertConfig, alertConfig.trendingCriteria]
  );

  const handleLocalOnlyChange = useCallback(
    (e) => {
      setAlertConfig((prevConfig) => ({
        ...prevConfig,
        localOnly: e.target.checked,
      }));
    },
    [setAlertConfig]
  );

  return {
    alertConfig,
    updateAlertConfig,
    handlers: {
      onKpiTypeChange: handleKpiTypeChange,
      onTrendingSectionChange: handleTrendingSectionChange,
      onTrendingAuthorChange: handleTrendingAuthorChange,
      onTrendingContentTypeChange: handleTrendingContentTypeChange,
      onTrendingSourceChange: handleTrendingSourceChange,
      onOperatorChange: handleOperatorChange,
      onThresholdValueChange: handleThresholdValueChange,
      onTimeRangeChange: handleTimeRangeChange,
      onLocalOnlyChange: handleLocalOnlyChange,
    },
  };
};
