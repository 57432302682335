import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import DataTableGrid from './DataTableGrid';
import { trackWidgetView } from '../../helpers/Analytics';

const RevenueByService = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [rows, setRowData] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getData = (props, page) => {
    if (props.apiRequestOptions) {
      setRowData(null);
      setFetchingData(true);
      page = page ? page : 1;

      var oParams = new URLSearchParams({
        page: page,
        pageSize: 5,
        sort: 'revenue',
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/revenue/revenue_by_service', oAxiosOptions).then(
        (response) => {
          setRowData(response.data.data.revenue_by_service);
          setTotalRows(response.data.totalRows);
          setCurrentPage(response.data.page);
          setFetchingData(false);
          trackWidgetView({ name: 'Signups By Rate ID' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handlePageChange = (newPage) => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props, newPage);
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingRevenueByService(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const options = {
    pageSize: 5,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    sortBy: 'revenue',
    approxHeight: 300, // useful for skeleton element while data is loading
  };

  const columns = [
    {
      field: 'id',
      maxWidth: 30,
      minWidth: 30,
      headerName: '',
      sortable: false,
      renderCell: (index) => index.api.getRowIndex(index.rowNode.id) + 1 + (currentPage - 1) * 5,
    },
    {
      field: 'service',
      headerName: 'Service',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.service} followCursor>
          <p>{params.row.service}</p>
        </Tooltip>
      ),
    },
    {
      field: 'revenue',
      headerName: (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography fontWeight={600} fontSize="14px" noWrap>
            Total Revenue
          </Typography>
          <Tooltip title="BLOX transaction revenue collected in this time period." placement="top" arrow enterTouchDelay={0}>
            <HelpOutlineIcon sx={{ ml: 0.5, top: -1, position: 'relative', fontSize: '1rem', verticalAlign: 'middle' }} />
          </Tooltip>
        </Box>
      ),
      sortable: false,
      flex: 1,
      headerAlign: 'right',
      type: 'number',
      maxWidth: 135,
      minWidth: 135,
      valueFormatter: (params) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mb={1} mr={1}>
        <Typography variant="h2" sx={{ mr: 0.2 }}>
          Revenue by Service
        </Typography>
        <Tooltip title="Services are pulled from BLOX Subscription" placement="top" arrow enterTouchDelay={0}>
          <HelpOutlineIcon sx={{ ml: 0.5, top: -1, position: 'relative', fontSize: '1rem', verticalAlign: 'middle' }} />
        </Tooltip>
      </Box>
      {rows !== null ? (
        <>
          <DataTableGrid columns={columns} rows={rows} options={options} />
        </>
      ) : (
        <Skeleton variant="rectangular" height={368} />
      )}
    </>
  );
};

export default RevenueByService;
