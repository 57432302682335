import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { DateTime } from 'luxon';
import DashboardToolbar from '../components/toolbars/DashboardToolbar';
import SectionTitle from '../components/SectionTitle';
import { Navigate, useOutletContext } from 'react-router-dom';
import Head from '../components/Head';
import TopAssetsByConversions from '../components/grids/TopAssetsByConversions';
import ConversionsByAccessRule from '../components/grids/ConversionsByAccessRule';
import ConversionsChart from '../components/charts/ConversionsChart';
import ServiceRateIDGrid from '../components/grids/ServiceRateIDGrid';
import SubscriptionIndustryKpis from '../components/kpis/SubscriptionIndustryKpis';
import SubscriptionRevenueKpis from '../components/kpis/SubscriptionRevenueKpis';

const Subscriptions = (props) => {
  const { permissions } = useOutletContext();
  const canViewSubscriptions = permissions && permissions.subscription;

  const { drawerOpen, setDrawerOpen } = useOutletContext();
  const { apiRequestOptions } = useOutletContext();
  const { userDefaultFilter } = useOutletContext();
  const { domains, setDomains } = useOutletContext();

  const [startDate, setStartDate] = useState(DateTime.now().minus({ days: 7 }));
  const [endDate, setEndDate] = useState(DateTime.now().minus({ days: 1 }));
  const [dateRangeLabel, setDateRangeLabel] = useState('Last 7 Days');

  const [loadingSubscriptionRevenueKpis, setLoadingSubscriptionRevenueKpis] = useState(true);
  const [loadingServiceRateIDGrid, setLoadingServiceRateIDGrid] = useState(true);
  const [loadingSubscriptionIndustryKpis, setLoadingSubscriptionIndustryKpis] = useState(true);
  const [loadingConversionsByAccessRule, setLoadingConversionsByAccessRule] = useState(true);
  const [loadingConversionsChart, setLoadingConversionsChart] = useState(true);
  const [loadingTopAssetsByConversions, setLoadingTopAssetsByConversions] = useState(true);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDomainChange = (event, newValue) => {
    setDomains(newValue);
  };

  const handleDateRangeChange = (start, end, label) => {
    setStartDate(start);
    setEndDate(end);
    setDateRangeLabel(label);
  };

  useEffect(() => {
    if (userDefaultFilter && !domains) {
      setDomains(userDefaultFilter.configuration.domains);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefaultFilter]);

  if (canViewSubscriptions === true) {
    return (
      <>
        <Head title="Subscriptions"></Head>
        <DashboardToolbar
          pageTitle="Subscriptions"
          handleDrawerToggle={handleDrawerToggle}
          apiRequestOptions={apiRequestOptions}
          domains={domains}
          handleDomainChange={handleDomainChange}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          disabled={
            loadingSubscriptionRevenueKpis ||
            loadingServiceRateIDGrid ||
            loadingSubscriptionIndustryKpis ||
            loadingConversionsByAccessRule ||
            loadingConversionsChart ||
            loadingTopAssetsByConversions
          }
        />
        <Box></Box>
        <Grid container spacing={3} sx={{ py: 3 }}>
          {/* subscription revenue box */}
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ mb: 3 }}>
              {/* subscription revenue KPIs */}
              <Box sx={{ p: 3 }}>
                <SectionTitle title="Subscription Revenue" size="lg" />
                <SubscriptionRevenueKpis
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingSubscriptionRevenueKpis={setLoadingSubscriptionRevenueKpis}
                />
              </Box>

              <Divider sx={{ pt: 1, mb: 5 }} />

              {/* service rate grid */}
              <Box p={3}>
                <ServiceRateIDGrid
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingServiceRateIDGrid={setLoadingServiceRateIDGrid}
                />
              </Box>
            </Paper>

            {/* Industry KPIs */}
            <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
              <SectionTitle title="Industry KPIs" label="Beta" size="lg" />
              {startDate < DateTime.fromISO('2022-07-01') && (
                <Alert severity="warning">
                  Data is not available for{' '}
                  {endDate > DateTime.fromISO('2022-07-31') ? 'part of the selected date range' : 'the selected date range'}.
                </Alert>
              )}
              <SubscriptionIndustryKpis
                domains={domains}
                startDate={startDate}
                endDate={endDate}
                apiRequestOptions={apiRequestOptions}
                setLoadingSubscriptionIndustryKpis={setLoadingSubscriptionIndustryKpis}
              />
            </Paper>

            {/* audience+ stuff */}
            <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
              <SectionTitle title="Purchases by Audience+" size="lg" />

              {/* conversions table */}
              <Box pt={2}>
                <ConversionsByAccessRule
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  dateRangeLabel={dateRangeLabel}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingConversionsByAccessRule={setLoadingConversionsByAccessRule}
                />
              </Box>

              <Grid container spacing={{ xs: 5, xl: 8 }} pt={6}>
                {/* conversions graph */}
                <Grid item xs={12} lg={5} xl={4}>
                  <ConversionsChart
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    setLoadingConversionsChart={setLoadingConversionsChart}
                  />
                </Grid>

                {/* top assets table */}
                <Grid item xs={12} lg={7} xl={8}>
                  <TopAssetsByConversions
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    setLoadingTopAssetsByConversions={setLoadingTopAssetsByConversions}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  } else if (canViewSubscriptions === false) {
    return <Navigate to="/signin" replace />;
  }
};

export default Subscriptions;
