import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';

const muiTypographyVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
];

export default function SectionTitle(props) {
  let tooltip = props.tooltip || props.tip;
  let variant = props.size || props.variant;

  // Check if the variant is a default MUI variant, otherwise prefix with 'header_'. This is to keep compatibility with the old variant names.
  if (!muiTypographyVariants.includes(variant)) {
    variant = 'header_' + variant;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant={variant} gutterBottom sx={{ mb: 0, pb: 2, alignItems: 'center' }}>
        {props.title}
        {tooltip && (
          <Tooltip title={tooltip} placement="top" arrow enterTouchDelay={0}>
            <HelpOutlineIcon sx={{ ml: 1, fontSize: '1rem', verticalAlign: 'middle' }} />
          </Tooltip>
        )}
        {props.label && <Chip label={props.label} size="small" sx={{ ml: 1, fontWeight: '400' }} />}
      </Typography>
    </Box>
  );
}
