import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import Kpi from './Kpi';
import StackedBarChart from '../charts/StackedBarChart';
import ExportButton from '../input/ExportButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useProcessingData } from '../../contexts/ProcessingDataContext';

const EditorialContentKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [articleData, setArticleData] = useState(null);
  const [collectionData, setCollectionData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const colors = theme.palette;
  const { updateProcessingStatusForKpi } = useProcessingData();

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const ResponsiveDivider = ({ children }) => {
    return <Divider orientation={lgUp ? 'vertical' : 'horizontal'} flexItem sx={{ width: { xs: '100%', lg: 'auto' } }} />;
  };

  const calcPeriod = () => {
    let totalDays = Math.ceil(props.startDate.diff(props.endDate, 'days').days) - 1;
    if (totalDays <= -365) {
      return 'monthly';
    } else if (totalDays < -31) {
      return 'weekly';
    }
    return 'daily';
  };

  const getStatsByType = (response, val) => {
    let stats = null;
    if (response.data && response.data.content_stats && response.data.content_stats.length > 0) {
      stats = response.data.content_stats.find((obj) => {
        return obj.type === val;
      });
    }
    return stats ? stats : { page_views: 0 };
  };

  const fetchData = () => {
    if (props.apiRequestOptions) {
      setLoadingData(true);
      setFetchingData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
        period: calcPeriod(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/content_summary', oAxiosOptions).then(
        (response) => {
          const newArticleData = getStatsByType(response, 'article');
          const newCollectionData = getStatsByType(response, 'collection');
          const newVideoData = getStatsByType(response, 'video');

          setArticleData(newArticleData);
          setCollectionData(newCollectionData);
          setVideoData(newVideoData);

          // Update processing status for each type of data
          updateProcessingStatusForKpi('Article Pageviews', newArticleData);
          updateProcessingStatusForKpi('Collection Pageviews', newCollectionData);
          updateProcessingStatusForKpi('Video Pageviews', newVideoData);

          var daysAgo = Math.ceil(props.startDate.diffNow('days').days);
          var totalDays = Math.ceil(props.startDate.diff(props.endDate, 'days').days) - 1;
          var dates = null;
          var labels = [];

          // get dates as Luxon date objects and create labels
          if (daysAgo === -7) {
            dates = response.data.period_stats.map((d) => DateTime.fromISO(d.date));
            labels = dates.map((d) => d.weekdayShort);
          } else if (totalDays <= -365) {
            dates = response.data.period_stats.map((d) => DateTime.fromISO(d.date));
            labels = dates.map((d) => d.monthShort);
          } else {
            dates = response.data.period_stats.map((d) => DateTime.fromISO(d.date));
            // if labels all share the same year, remove the /YYYY
            if (dates[0] && dates[0].year === dates[dates.length - 1].year) {
              labels = dates.map((d) => d.toLocaleString({ month: 'numeric', day: 'numeric' }));
            } else {
              labels = dates.map((d) => d.toLocaleString());
            }
          }

          setChartData({
            labels,
            datasets: [
              {
                label: 'Articles',
                backgroundColor: theme.palette.chart.green.main,
                data: response.data.period_stats.map((d) => d.article_page_views),
              },
              {
                label: 'Total',
                backgroundColor: theme.palette.chart.blue.main,
                data: response.data.period_stats.map((d) => d.page_views),
              },
            ],
          });

          setFetchingData(false);
          setLoadingData(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingEditorialContentKpis(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  return (
    <>
      <Grid container sx={{ mb: 5, px: 3, gap: 2 }}>
        <Grid item xs={12} lg>
          {!loadingData && articleData && 'page_views' in articleData ? (
            <Kpi
              value={articleData.page_views}
              label="Article Pageviews"
              diff={articleData.page_views_diff_pct}
              period={articleData.days_diff}
              align={lgUp ? 'center' : ''}
            />
          ) : (
            <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
          )}
        </Grid>

        <ResponsiveDivider />

        <Grid item xs={12} lg>
          {!loadingData && collectionData && 'page_views' in collectionData ? (
            <Kpi
              value={collectionData.page_views}
              label="Collection Pageviews"
              diff={collectionData.page_views_diff_pct}
              period={collectionData.days_diff}
              align={lgUp ? 'center' : ''}
            />
          ) : (
            <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
          )}
        </Grid>

        <ResponsiveDivider />

        <Grid item xs={12} lg>
          {!loadingData && videoData && 'page_views' in videoData ? (
            <Kpi
              value={videoData.page_views}
              label="Video Pageviews"
              diff={videoData.page_views_diff_pct}
              period={videoData.days_diff}
              align={lgUp ? 'center' : ''}
            />
          ) : (
            <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
          )}
        </Grid>
      </Grid>

      <Box sx={{ px: 3 }}>
        <Box sx={{ maxWidth: { lg: '50vw' }, mx: 'auto' }}>
          {!loadingData && chartData ? (
            <Box>
              <Box sx={{ textAlign: 'right', mt: 1 }}>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  disabled={fetchingData}
                  onClick={handleMenuToggle}>
                  <MoreVertOutlinedIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClick={() => setAnchorEl(null)}
                  keepMounted
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiMenuItem-root': {
                      gap: 1,
                      paddingLeft: '12px',
                      fontSize: '.9em',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.25rem',
                      color: colors.primary.light,
                    },
                  }}>
                  <MenuItem sx={{ padding: '0 !important' }}>
                    <ExportButton
                      variant="link"
                      sx={{
                        color: 'white',
                        gap: 1,
                        paddingLeft: '12px',
                        fontSize: '.9em',
                        fontWeight: 400,
                      }}
                      modal={true}
                      startImmediateExport={true}
                      {...props}
                      export={{
                        api: '/api/v1/site/content_summary',
                        name: 'localarticles-VS-pageviews',
                        params: {
                          exportComponent: 'period_stats',
                          period: calcPeriod(),
                        },
                      }}
                    />
                  </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ position: 'relative', width: '100%', height: 300, mt: 1 }}>
                <StackedBarChart data={chartData} height={300} type="vertical" labely="Pageviews"></StackedBarChart>
              </Box>
            </Box>
          ) : (
            <Skeleton variant="rectangular" height={400} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditorialContentKpis;
