import React from 'react';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
  CircularProgress,
  Fade,
  InputLabel,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useKPI } from '../../providers/AlertKPIProvider';
import KpiTypeSelect from './KpiTypeSelect';
import { contentTypes, sources } from '../../constants';
import { useCreateAlertModalContext } from './CreateAlertModalContext.tsx';

const AlertTriggerConfiguration = ({ alertConfig, handlers, sections, sectionsError }) => {
  const { currentKPI } = useKPI();
  const shouldRender = !!alertConfig.alertDashboard;
  const isTimeRangeRestricted =
    alertConfig.alertDashboard === 'subscriptions' ||
    (alertConfig.alertDashboard === 'revenue' && alertConfig.kpiType.endpoint === 'other_sources');
  const { setSectionSearchQuery, isSectionsLoading } = useCreateAlertModalContext();

  return (
    <Collapse in={shouldRender} timeout="auto" unmountOnExit>
      {alertConfig.alertDashboard === 'trending' ? (
        <Paper elevation={1} sx={{ p: 2, my: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Alert Criteria
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Trigger an alert when trending status is <strong>"On Fire"</strong> and matches the following criteria:
          </Typography>
          <FormControl fullWidth sx={{ my: 1 }} size="small">
            <Autocomplete
              id="section-select"
              size="small"
              freeSolo
              selectOnFocus
              clearOnBlur
              options={sections?.data || []}
              value={alertConfig.trendingCriteria.section || ''}
              onChange={(event, newValue) => handlers.onTrendingSectionChange(newValue)}
              onInputChange={(event, newInputValue) => setSectionSearchQuery(newInputValue)}
              loading={isSectionsLoading}
              noOptionsText={isSectionsLoading ? 'Loading...' : 'No results found'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sections"
                  placeholder="All sections"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isSectionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ my: 1 }} size="small">
            <TextField
              id="author-field"
              type="email"
              size="small"
              label="Author email address"
              placeholder="All authors"
              value={alertConfig.trendingCriteria.author}
              onChange={handlers.onTrendingAuthorChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ my: 1 }} size="small">
            <Autocomplete
              multiple
              limitTags={1}
              id="content-type-select"
              size="small"
              options={contentTypes}
              value={alertConfig.trendingCriteria.contentType}
              onChange={handlers.onTrendingContentTypeChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Content Type"
                  placeholder={alertConfig.trendingCriteria.contentType.length === 0 ? 'All Content Types' : ''}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ my: 1 }} size="small">
            <Autocomplete
              id="source-select"
              freeSolo
              size="small"
              options={sources}
              value={alertConfig.trendingCriteria.source}
              onInputChange={(event, newValue) => handlers.onTrendingSourceChange(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Traffic Source" placeholder="All sources" InputLabelProps={{ shrink: true }} />
              )}
            />
          </FormControl>
        </Paper>
      ) : (
        <>
          <Paper elevation={1} sx={{ p: 2, my: 2 }}>
            <Box display="flex" alignItems="baseline" justifyContent="space-between">
              <Typography variant="subtitle2" gutterBottom>
                Alert Criteria
              </Typography>
              <Fade
                in={alertConfig.alertDashboard === 'editorial' && alertConfig.kpiType.endpoint.startsWith('productivity')}
                timeout={{
                  exit: 0,
                  enter: 100,
                }}>
                <Tooltip title="Only trigger the alert for local content" placement="top">
                  <FormControlLabel
                    sx={{
                      margin: 0,
                    }}
                    control={
                      <Switch
                        checked={alertConfig.localOnly}
                        onChange={handlers.onLocalOnlyChange}
                        name="localOnly"
                        color="primary"
                        size="small"
                      />
                    }
                    label={<Typography variant="body2">Local Only</Typography>}
                  />
                </Tooltip>
              </Fade>
            </Box>
            {!currentKPI && (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Box minWidth={100}>
                  <Typography variant="body1">Select a KPI:</Typography>
                </Box>
                <KpiTypeSelect
                  value={alertConfig.kpiType}
                  onChange={handlers.onKpiTypeChange}
                  dashboard={alertConfig.alertDashboard}
                />
              </Box>
            )}
            <Collapse in={!!alertConfig.kpiType.label} timeout="auto" unmountOnExit>
              <Box display={!alertConfig.kpiType.label ? 'none' : 'block'} mt={currentKPI ? 0 : 2}>
                <Box display="flex" alignItems="baseline">
                  <Typography variant="body1" mr={1}>
                    When the value of
                  </Typography>
                  {alertConfig.kpiType.label ? (
                    <Typography variant="body1" color="primary" fontWeight="bold" mr={1}>
                      {alertConfig.kpiType.label}
                    </Typography>
                  ) : (
                    <Typography variant="body1" color="primary" fontWeight="bold" mr={1}>
                      KPI Not Selected
                    </Typography>
                  )}
                  <Typography variant="body1" mr={1}>
                    is:
                  </Typography>
                </Box>
                <Box display="flex" alignItems="baseline" mb={2}>
                  <FormControl size="small" sx={{ minWidth: 150, mr: 1 }}>
                    <InputLabel id="operator-select-label">Operator</InputLabel>
                    <Select
                      name="operator"
                      labelId="operator-select-label"
                      value={alertConfig.operator}
                      onChange={handlers.onOperatorChange}
                      label="Operator">
                      <MenuItem value="<">less than</MenuItem>
                      <MenuItem value="==">equal to</MenuItem>
                      <MenuItem value=">">greater than</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    margin="dense"
                    name="thresholdValue"
                    size="small"
                    fullWidth
                    value={alertConfig.thresholdValue}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^\d*$/.test(value)) {
                        handlers.onThresholdValueChange(event);
                      }
                    }}
                    label="Threshold Value"
                  />
                </Box>

                <Typography variant="subtitle2" gutterBottom>
                  Time Frame
                </Typography>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Typography variant="body1" mr={1}>
                    Alert Data Range:
                  </Typography>
                  <Tooltip title="The selected range is recurring and based on the current day." placement="top">
                    <InfoIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
                  </Tooltip>
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel id="time-range-select-label">Time Range</InputLabel>
                    <Select
                      name="range"
                      value={alertConfig.timeRange}
                      onChange={handlers.onTimeRangeChange}
                      label="Time Range"
                      labelId="time-range-select-label">
                      {!isTimeRangeRestricted && <MenuItem value="week">Last 7 Days</MenuItem>}
                      <MenuItem value="month">Last 30 Days</MenuItem>
                      <MenuItem value="year">Last 12 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Collapse>
          </Paper>
        </>
      )}
    </Collapse>
  );
};

export default AlertTriggerConfiguration;
