import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from '../components/Navbar';
import ErrorSnackbar from '../components/ErrorSnackbar';
import jwt_decode from 'jwt-decode';

const drawerWidth = 240;

const Index = (props) => {
  const { window } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();
  const { dashboardId } = useParams();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [filtersReady, setFiltersReady] = useState(false);
  const [userFilters, setUserFilters] = useState(null);
  const [userDefaultFilter, setUserDefaultFilter] = useState(null);
  const [apiRequestOptions, setApiRequestOptions] = useState(null);
  const [displayError, setDisplayError] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [domains, setDomains] = useState(null);
  const [section, setSection] = useState('');
  const [author, setAuthor] = useState('');
  const [contentTypes, setContentTypes] = useState([{ id: 'article', label: 'Article' }]);
  const [source, setSource] = useState('');
  const [columns, setColumns] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState(null);
  const [userChangedColumnVisibility, setUserChangedColumnVisibility] = useState(false);

  // New state for platform
  const [platform, setPlatform] = useState('');

  const emptyFilter = {
    configuration: {
      domains: [],
      section: '',
      author: '',
      contentTypes: [{ id: 'article', label: 'Article' }],
      source: '',
      columnVisibility: null,
      platform: '', // Added platform to the empty filter
    },
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const fetchUserFilters = () => {
    var oParams = new URLSearchParams({
      page: 1,
      pageSize: 10,
    });

    fetch('/api/v1/filters?' + oParams, apiRequestOptions)
      .then((response) => {
        return handleApiResponse(response);
      })
      .then((data) => {
        if (data.filters && Array.isArray(data.filters) && data.filters.length) {
          setUserFilters(data.filters);
        } else {
          setUserDefaultFilter(emptyFilter);
          setFiltersReady(true);
        }
      })
      .catch((error) => {
        setDisplayError(error);
      });
  };

  const fetchUserDefaultFilter = (filterId) => {
    fetch('/api/v1/filters/' + filterId, apiRequestOptions)
      .then((response) => {
        return handleApiResponse(response);
      })
      .then((data) => {
        if (data) {
          data.id = filterId;
          setUserDefaultFilter(data);
          setFiltersReady(true);
        }
      })
      .catch((error) => {
        setDisplayError(error);
      });
  };

  const fetchPublicAccessToken = (dashboardId) => {
    let oOptions = { method: 'POST', body: JSON.stringify({ filter_id: dashboardId }) };
    fetch('/api/v1/filters/token', oOptions)
      .then((response) => {
        return handleApiResponse(response);
      })
      .then((data) => {
        if (data) {
          if (data.access_token) {
            setPermissions(createPermissions(data.access_token));
            setApiRequestOptions(createReqOptions(data.access_token));
          }
          setUserDefaultFilter(data.filter);
        }
      })
      .then(() => {
        setFiltersReady(true);
      })
      .catch((error) => {
        setDisplayError(error);
      });
  };

  const handleApiResponse = (response) => {
    if (response.ok) {
      return response.json();
    }
    if (!isAuthenticated) {
      navigate('/signin', { replace: true });
    }
    throw new Error(response.status + ': ' + response.statusText);
  };

  const createReqOptions = (token) => {
    var sBearer = 'Bearer ' + token;
    var oReqOptions = {
      headers: {
        authorization: sBearer,
      },
    };
    return oReqOptions;
  };

  const createPermissions = (token) => {
    let jwt = jwt_decode(token);
    let permissions = {
      trending: jwt.permissions.includes('datainsights:view-dashboard:trending'),
      editorial: jwt.permissions.includes('datainsights:view-dashboard:editorial'),
      subscription: jwt.permissions.includes('datainsights:view-dashboard:subscription'),
      revenue: jwt.permissions.includes('datainsights:view-dashboard:revenue'),
    };
    return permissions;
  };

  useEffect(() => {
    if (isAuthenticated && apiRequestOptions && !dashboardId) {
      fetchUserFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequestOptions]);

  useEffect(() => {
    if (userFilters && userFilters.length > 0) {
      fetchUserDefaultFilter(userFilters[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilters]);

  useEffect(() => {
    const getAccessToken = async () => {
      const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

      try {
        const accessToken = await getAccessTokenSilently({
          audience: audience,
          scope: [
            'datainsights:view:dashboard',
            'datainsights:manage:filters',
            'datainsights:view-dashboard:trending',
            'datainsights:view-dashboard:editorial',
            'datainsights:view-dashboard:subscription',
          ].join(' '),
        });

        if (accessToken) {
          setPermissions(createPermissions(accessToken));
          setApiRequestOptions(createReqOptions(accessToken));
        }
      } catch (error) {
        setDisplayError(error);
      }
    };

    if (isAuthenticated) {
      getAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (dashboardId && !apiRequestOptions) {
      fetchPublicAccessToken(dashboardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId]);

  useEffect(() => {
    if (userDefaultFilter) {
      if (!domains) setDomains(userDefaultFilter.configuration.domains);
      setSection(userDefaultFilter.configuration.section);
      setAuthor(userDefaultFilter.configuration.author);
      setContentTypes(userDefaultFilter.configuration.contentTypes);
      setSource(userDefaultFilter.configuration.source);
      setPlatform(userDefaultFilter.configuration.platform); // Setting platform
      if (userDefaultFilter.configuration.columnVisibility)
        setColumnVisibility(userDefaultFilter.configuration.columnVisibility);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefaultFilter]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{ onClick: handleDrawerToggle, elevation: 1 }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}>
          <Navbar permissions={permissions} />
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 0 }}
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open>
          <Navbar permissions={permissions} />
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}>
        <Outlet
          context={{
            drawerOpen,
            setDrawerOpen,
            userDefaultFilter,
            setUserDefaultFilter,
            domains,
            setDomains,
            section,
            setSection,
            author,
            setAuthor,
            contentTypes,
            setContentTypes,
            source,
            setSource,
            columns,
            setColumns,
            columnVisibility,
            setColumnVisibility,
            userChangedColumnVisibility,
            setUserChangedColumnVisibility,
            filtersReady,
            apiRequestOptions,
            displayError,
            setDisplayError,
            permissions,
            platform, // Added platform
            setPlatform, // Added setPlatform
          }}
        />
        <ErrorSnackbar displayError={displayError} />
      </Box>
    </Box>
  );
};

export default Index;
