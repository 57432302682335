import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import Kpi from './Kpi';
import { trackWidgetView } from '../../helpers/Analytics';

const RevenueWebKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [kpiData, setKpiData] = useState(null);
  const [fetchingKpiData, setfetchingKpiData] = useState(false);
  const [loadingKpis, setLoadingKpis] = useState(true);

  const fetchKpiData = () => {
    if (props.apiRequestOptions) {
      setLoadingKpis(true);
      setfetchingKpiData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/revenue/subscription', oAxiosOptions).then(
        (response) => {
          setKpiData(response.data);
          setfetchingKpiData(false);
          setLoadingKpis(false);
          trackWidgetView({ name: 'Web Subscription Revenue KPIs' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingKpiData && props.domains) {
      fetchKpiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingRevenueWebKpis(fetchingKpiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingKpiData]);

  return (
    <>
      <Box sx={{ px: 3 }}>
        {!loadingKpis && kpiData && ['total_subscription_revenue', 'days_diff'].every((key) => key in kpiData) ? (
          <Kpi
            type="money"
            value={kpiData.total_subscription_revenue}
            label="Total Revenue"
            diff={kpiData.total_subscription_revenue_diff_pct}
            period={kpiData.days_diff}
            tip="BLOX transaction revenue collected in this time period."
          />
        ) : (
          <Skeleton variant="rectangular" height={110} />
        )}
      </Box>

      <Divider sx={{ my: 3 }}></Divider>

      <Box sx={{ px: 3 }}>
        {!loadingKpis && kpiData && ['new_subscription_revenue', 'days_diff'].every((key) => key in kpiData) ? (
          <Kpi
            type="money"
            value={kpiData.new_subscription_revenue}
            label="New Subscriber Revenue"
            diff={kpiData.new_subscription_revenue_diff_pct}
            period={kpiData.days_diff}
            tip="BLOX transaction revenue collected in this time period for new subscriptions."
          />
        ) : (
          <Skeleton variant="rectangular" height={110} />
        )}
      </Box>
    </>
  );
};

export default RevenueWebKpis;
