import React from 'react';
import { CreateAlertModalProvider } from './CreateAlertModalContext.tsx';
import CreateAlertModal from './CreateAlertModal';
import { useAlertsContext } from '../../providers/AlertsProvider';

const CreateAlert = () => {
  const { isModalOpen, handleModalClose } = useAlertsContext();
  return (
    <CreateAlertModalProvider open={isModalOpen} onClose={handleModalClose}>
      {isModalOpen && <CreateAlertModal />}
    </CreateAlertModalProvider>
  );
};

export default CreateAlert;
