import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DashboardToolbar from '../components/toolbars/DashboardToolbar';
import Head from '../components/Head';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExtensionIcon from '@mui/icons-material/Extension';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertsView from '../components/settings/Alerts/AlertsView';
import IntegrationsView from '../components/settings/Integrations/IntegrationsView';

const tabsConfig = [
  {
    label: 'Alerts',
    icon: <NotificationsIcon fontSize="small" />,
    path: '/settings/alerts',
    content: <AlertsView />,
  },
  {
    label: 'Integrations',
    icon: <ExtensionIcon fontSize="small" />,
    path: '/settings/integrations',
    content: <IntegrationsView />,
  },
];

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const currentTab = tabsConfig.findIndex((tab) => tab.path === location.pathname);
    if (currentTab >= 0) {
      setSelectedTab(currentTab);
    } else {
      navigate(tabsConfig[0].path);
    }
  }, [location.pathname, navigate]);

  const handleTabChange = (event, newValue) => {
    navigate(tabsConfig[newValue].path);
  };

  const tabContent = useMemo(() => tabsConfig[selectedTab]?.content, [selectedTab]);

  if (tabsConfig.length === 0) {
    console.error('No tabs configured');
    // Handle empty tabsConfig, e.g., display an error message or redirect
    return;
  }

  return (
    <>
      <Head title="Settings" />
      <DashboardToolbar pageTitle="Settings" />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="settings tabs">
            {tabsConfig.map((tab, index) => (
              <Tab key={index} label={tab.label} icon={tab.icon} iconPosition="start" />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Grid container spacing={3} sx={{ py: 3 }}>
        <Grid item xs={12}>
          {tabContent}
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
