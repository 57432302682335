import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import SectionTitle from '../SectionTitle';
import Kpi from './Kpi';
import { trackWidgetView } from '../../helpers/Analytics';
import Placeholder from '../charts/Placeholder';

const RevenueOtherKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setfetchingData] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingIqData, setLoadingIqData] = useState(true);
  const [isMonthly, setIsMonthly] = useState(false);
  const [contentExchange, setContentExchange] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [iqAdOpsRevenue, setIqAdOpsRevenue] = useState(null);
  const [contentExchangeDiff, setContentExchangeDiff] = useState(null);
  const [recommendationsDiff, setRecommendationsDiff] = useState(null);
  const [iqAdOpsRevenueDiff, setIqAdOpsRevenueDiff] = useState(null);
  const [daysDiff, setDaysDiff] = useState(null);

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const ResponsiveDivider = ({ children }) => {
    return <Divider orientation={lgUp ? 'vertical' : 'horizontal'} flexItem sx={{ width: { xs: '100%', lg: 'auto' } }} />;
  };

  const fetchData = () => {
    if (props.apiRequestOptions) {
      setLoadingData(true);
      setLoadingIqData(true);
      setfetchingData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/revenue/other_sources', oAxiosOptions).then(
        (response) => {
          if (
            response.data &&
            response.data.hasOwnProperty('content_exchange') &&
            response.data.hasOwnProperty('recommendations')
          ) {
            let data = response.data;
            setContentExchange(data.content_exchange || 0);
            setRecommendations(data.recommendations || 0);
            setContentExchangeDiff(data.content_exchange_diff_pct);
            setRecommendationsDiff(data.recommendations_diff_pct);
            setDaysDiff(data.days_diff);
          } else {
            setDisplayError({ message: 'Unexpected data format.' });
          }

          setfetchingData(false);
          setLoadingData(false);
          trackWidgetView({ name: 'Other Revenue KPIs' });
        },
        (error) => {
          setDisplayError(error);
        }
      );

      axios.get('/api/v1/revenue/iq_adops', oAxiosOptions).then(
        (response) => {
          if (response.data && response.data.hasOwnProperty('iq_adops')) {
            let data = response.data;
            setIqAdOpsRevenue(data.iq_adops);
            setIqAdOpsRevenueDiff(data.iq_adops_diff_pct);
            setDaysDiff(data.days_diff);
          } else {
            setDisplayError({ message: 'Unexpected data format.' });
          }

          setfetchingData(false);
          setLoadingIqData(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains && isMonthly) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, isMonthly]);

  useEffect(() => {
    if (props.startDate && props.endDate) {
      let totalMonths = props.endDate.endOf('day').diff(props.startDate.startOf('day'), 'months').months;
      let isMonthly = totalMonths >= 0.98;
      setIsMonthly(isMonthly);
      if (isMonthly) {
        fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingRevenueOtherKpis(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  return (
    <>
      <SectionTitle
        title="Other Revenue Sources"
        tooltip="Data is not finalized until the 7th of the following month"
        size="lg"
      />
      <Grid container sx={{ mt: 2, gap: 2 }}>
        <Grid item xs={12} lg>
          {(() => {
            if (isMonthly) {
              if (!loadingData && !isNaN(parseInt(contentExchange))) {
                return (
                  <Kpi
                    value={contentExchange}
                    type="money"
                    label="Content Exchange"
                    diff={contentExchangeDiff}
                    period={daysDiff}
                    align={lgUp ? 'center' : ''}
                  />
                );
              } else {
                return <Skeleton variant="rectangular" height={110} width={'80%'} sx={{ mx: 'auto' }} />;
              }
            } else {
              return (
                <Placeholder elevation={0} height={110}>
                  No Data
                </Placeholder>
              );
            }
          })()}
        </Grid>

        <ResponsiveDivider />

        <Grid item xs={12} lg>
          {(() => {
            if (isMonthly) {
              if (!loadingData && !isNaN(parseInt(recommendations))) {
                return (
                  <Kpi
                    value={recommendations}
                    type="money"
                    label="Recommendations"
                    diff={recommendationsDiff}
                    period={daysDiff}
                    align={lgUp ? 'center' : ''}
                  />
                );
              } else {
                return <Skeleton variant="rectangular" height={110} width={'80%'} sx={{ mx: 'auto' }} />;
              }
            } else {
              return (
                <Placeholder elevation={0} height={110}>
                  No Data
                </Placeholder>
              );
            }
          })()}
        </Grid>

        <ResponsiveDivider />

        <Grid item xs={12} lg>
          {(() => {
            if (isMonthly) {
              if (!loadingIqData) {
                return (
                  <Kpi
                    value={iqAdOpsRevenue}
                    type="money"
                    label="IQ AdOps Revenue"
                    diff={iqAdOpsRevenueDiff}
                    period={daysDiff}
                    align={lgUp ? 'center' : ''}
                    tip="Must use iQ AdOps for data to populate"
                  />
                );
              } else {
                return <Skeleton variant="rectangular" height={110} width={'80%'} sx={{ mx: 'auto' }} />;
              }
            } else {
              return (
                <Placeholder elevation={0} height={110}>
                  No Data
                </Placeholder>
              );
            }
          })()}
        </Grid>
      </Grid>
    </>
  );
};

export default RevenueOtherKpis;
