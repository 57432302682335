import { Box, TableContainer } from '@mui/material';
import CreateAlert from '../../alerts/index.tsx';
import LoadingAlerts from './LoadingAlerts';
import ErrorAlerts from './ErrorAlerts';
import EmptyAlerts from './EmptyAlerts';
import AlertsTable from './AlertsTable';
import { useAlertsContext } from '../../../providers/AlertsProvider';

const AlertsView = () => {
  const { alerts, isLoading, error, onRetry } = useAlertsContext();

  return (
    <Box>
      <CreateAlert />

      <TableContainer>
        {error && <ErrorAlerts onRetry={onRetry} />}
        {!error && alerts && alerts.length === 0 && !isLoading && <EmptyAlerts />}
        {!error && alerts && alerts.length > 0 && <AlertsTable isLoading={isLoading} />}
        {isLoading && alerts.length === 0 && <LoadingAlerts />}
      </TableContainer>
    </Box>
  );
};

export default AlertsView;
