import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme, alpha } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Stack from '@mui/material/Stack';
import TrendingGridSkeleton from './TrendingGridSkeleton';
import ArticleDetailsModal from '../modals/ArticleDetailsModal';
import Tooltip from '@mui/material/Tooltip';

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
});

// function isMultiDomain(domains) {
//   return domains.length !== 1 ? true : false;
// }

function isTrendingUp(params) {
  return params.row.status === 'trending_up';
}

function isTrendingDown(params) {
  return params.row.status === 'trending_down';
}

function isOnFire(params) {
  return params.row.status === 'fire';
}

function getSubscriberPercent(params) {
  let percentage = params.row.users < 1 ? 0 : (params.row.subscribers / params.row.users).toFixed(2);
  return percentFormatter.format(Number(percentage));
}

function getTrendCell(params) {
  if (isOnFire(params)) {
    return (
      <>
        <Box className="icon-circle icon-ping"></Box>
        <Box className="icon-circle">
          <LocalFireDepartmentIcon color="warning" />
        </Box>
      </>
    );
  } else if (isTrendingUp(params)) {
    return (
      <Box className="icon-circle">
        <TrendingUpIcon color="success" />
      </Box>
    );
  } else if (isTrendingDown(params)) {
    return (
      <Box className="icon-circle">
        <KeyboardDoubleArrowDownIcon color="error" />
      </Box>
    );
  }
  return '';
}

function getTrendingClassName(params) {
  if (isOnFire(params)) {
    return 'trending-fire';
  } else if (isTrendingUp(params)) {
    return 'trending-up';
  } else if (isTrendingDown(params)) {
    return 'trending-down';
  }
}

const columns = [
  {
    field: 'trend',
    headerName: '',
    cellClassName: 'cell-trend',
    renderCell: getTrendCell,
    align: 'right',
    minWidth: 56,
    maxWidth: 56,
    sortable: false,
  },
  {
    field: 'users',
    headerName: 'Users',
    type: 'number',
    cellClassName: 'cell-users',
    minWidth: 50,
    maxWidth: 70,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    cellClassName: 'cell-headline',
    flex: 1,
    minWidth: 217,
    sortable: false,
  },
  {
    field: 'hostname',
    headerName: 'Website',
    cellClassName: 'cell-website',
    flex: 0.12,
    minWidth: 140,
    sortable: false,
  },
  {
    field: 'content_group',
    headerName: 'Section',
    cellClassName: 'cell-section',
    flex: 0.12,
    minWidth: 140,
    sortable: false,
  },
  {
    field: 'author_name',
    headerName: 'Author',
    cellClassName: 'cell-author',
    flex: 0.12,
    minWidth: 140,
    sortable: false,
  },
  {
    field: 'content_type',
    headerName: 'Type',
    cellClassName: 'cell-type',
    flex: 0.12,
    minWidth: 90,
    sortable: false,
  },
  {
    field: 'subscribers',
    headerName: 'Subscribers',
    cellClassName: 'cell-subscribers',
    valueGetter: getSubscriberPercent,
    headerAlign: 'right',
    align: 'right',
    flex: 0.11,
    sortable: false,
  },
  {
    field: 'pageviews',
    headerName: 'Views',
    cellClassName: 'cell-views',
    type: 'number',
    flex: 0.08,
    headerAlign: 'right',
    align: 'right',
    sortable: false,
  },
];

const TrendingGrid = (props) => {
  const theme = useTheme();

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const handleDetailsOpen = () => setDetailsOpen(true);
  const handleDetailsClose = () => setDetailsOpen(false);

  const handleRowClick = (param, event) => {
    setSelectedRow(param.row);
    handleDetailsOpen(event);
  };

  const onPageChange = (newPage) => {
    if (typeof newPage === 'number' && !isNaN(newPage)) {
      props.handlePageChange(newPage + 1);
    }
  };

  // set columns for the first (and only) time
  useEffect(() => {
    if (props.columns === undefined || props.columns === null) {
      let c = columns
        .filter((c) => c.field !== 'trend')
        .map((c) => {
          return {
            field: c.field,
            name: c.headerName,
            checked: ['content_type', 'content_group', 'author_name'].includes(c.field) ? false : true,
            disabled: ['users', 'title'].includes(c.field) ? true : false,
          };
        });
      props.setColumns(c);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
        '& .MuiDataGrid-root .MuiDataGrid-cell': { borderColor: theme.palette.divider },
        '& .trending-fire .cell-headline .MuiDataGrid-cellContent': {
          backgroundColor: alpha(theme.palette.warning.main, 0.32),
          padding: '5px 10px',
        },
        '& .trending-up .cell-headline .MuiDataGrid-cellContent': {
          backgroundColor: alpha(theme.palette.success.main, 0.32),
          padding: '5px 10px',
        },
        '& .icon-circle': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '36px',
          height: '36px',
          borderRadius: '50%',
        },
        '& .trending-fire .icon-circle.icon-ping': {
          position: 'absolute',
          backgroundColor: alpha(theme.palette.warning.main, 0.32),
          animation: 'ping 1s cubic-bezier(0,0,.2,1) infinite',
        },
        '& .MuiChip-root.trending-fire, & .trending-fire .icon-circle': {
          backgroundColor: alpha(theme.palette.warning.main, 0.32),
        },
        '& .trending-fire .MuiChip-icon': {
          color: theme.palette.warning.main,
        },
        '& .trending-up .MuiChip-icon': {
          color: theme.palette.success.main,
        },
        '& .trending-down .MuiChip-icon': {
          color: theme.palette.error.main,
        },
        '& .MuiChip-root.trending-up, & .trending-up .icon-circle': {
          backgroundColor: alpha(theme.palette.success.main, 0.32),
        },
        '& .MuiChip-root.trending-down, & .trending-down .icon-circle': {
          backgroundColor: alpha(theme.palette.error.main, 0.32),
        },
        '& .data-row': {
          transition: theme.transitions.create(['all'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.complex,
          }),
        },
      }}>
      {props.loading ? (
        <TrendingGridSkeleton />
      ) : props.tableData ? (
        <Box
          sx={{
            display: 'flex',
            height: 'auto',
            marginLeft: { xs: '-24px', sm: '0' },
            marginRight: { xs: '-24px', sm: '0' },
          }}>
          <DataGrid
            rows={props.tableData.data || []}
            rowCount={Number(props.tableData.totalCount) || 0}
            rowsPerPageOptions={[10]}
            getRowClassName={getTrendingClassName}
            columns={columns}
            page={props.tableData.page - 1}
            pageSize={10}
            pagination
            paginationMode="server"
            onPageChange={onPageChange}
            density="comfortable"
            autoHeight
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            loading={props.loading}
            onRowClick={handleRowClick}
            initialState={{
              sorting: {
                sortModel: [{ field: 'users', sort: 'desc' }],
              },
            }}
            columnVisibilityModel={props.columnVisibility || {}}
            sx={{
              borderRadius: 0,
              borderColor: theme.palette.divider,
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
            }}
          />
        </Box>
      ) : null}
      <Stack direction="row" spacing={2} sx={{ pt: 4, pb: 1 }} justifyContent="center" alignItems="center">
        <Tooltip title="Decreased by 25% or more in the last 30 minutes" placement="top" arrow enterTouchDelay={0}>
          <Chip className="trending-down" label="25%" icon={<KeyboardDoubleArrowDownIcon />}></Chip>
        </Tooltip>
        <Tooltip title="Increased by 10-25% in the last 30 minutes" placement="top" arrow enterTouchDelay={0}>
          <Chip className="trending-up" label="10-25%" icon={<TrendingUpIcon />}></Chip>
        </Tooltip>
        <Tooltip title="Increased by 25% or more in the last hour" placement="top" arrow enterTouchDelay={0}>
          <Chip className="trending-fire" label="25%" icon={<LocalFireDepartmentIcon />}></Chip>
        </Tooltip>
      </Stack>
      <ArticleDetailsModal
        detailsOpen={detailsOpen}
        handleDetailsOpen={handleDetailsOpen}
        handleDetailsClose={handleDetailsClose}
        article={selectedRow}
        apiRequestOptions={props.apiRequestOptions}
      />
    </Box>
  );
};

export default TrendingGrid;
