import { createTheme } from '@mui/material/styles';

const CHART_COLORS = {
  blue: { main: 'rgb(24, 144, 255)', dark: 'rgb(12, 72, 127)', o50: 'rgb(24, 144, 255, 0.5)' },
  green: { main: 'rgb(0, 171, 85)', bright: 'rgb(84, 214, 44)', dark: 'rgb(0, 82, 73)', o50: 'rgb(0, 171, 85, 0.5)' },
  yellow: { main: 'rgb(255, 193, 7)', dark: 'rgb(127, 96, 3)', o50: 'rgb(255, 193, 7, 0.5)' },
  red: { main: 'rgb(255, 72, 66)' },
  orange: { main: 'rgb(255, 108, 64)' },
  purple: { main: 'rgb(130, 106, 249)' },
  violet: { main: 'rgb(208, 174, 255)' },
  teal: { main: 'rgb(44, 217, 197)' },
};

const PRIMARY = {
  main: 'rgb(0, 171, 85)',
  dark: 'rgb(0, 123, 85)',
};

const SECONDARY = {
  main: 'hsl(209,100%, 45%)',
  dark: 'hsl(209,100%, 35%)',
  light: 'hsl(209,100%, 55%)',
  contrastText: '#ffffff',
};
const NEUTRAL = {
  main: 'rgb(255, 255, 255)',
  o50: 'rgb(255, 255, 255, 0.5)',
  o12: 'rgb(255, 255, 255, 0.12)',
  contrastText: 'rgb(0, 0, 0)',
};

const ERROR = {
  main: 'rgb(255, 72, 66)',
};

const WARNING = {
  main: 'rgb(255, 193, 7)',
};

const SUCCESS = {
  main: 'rgb(84, 214, 44)',
  dark: 'rgb(0, 171, 85)',
};

const LINK = {
  main: 'rgb(24, 144, 255)',
};

const BACKGROUND = {
  default: 'rgb(22, 28, 36)',
  paper: 'rgb(33, 43, 54)',
  dark: 'hsl(211,24%,11%)',
};

const TABLE = {
  border: '#313E4D',
  hover: '#293541',
  link: 'rgb(0, 209, 105)',
};

const BLACK = {
  light: 'hsl(218,20%,13%)',
  main: 'hsl(218,20%,8%)',
  dark: 'black',
  contrastText: '#fff',
};

const customPalette = {
  mode: 'dark',
  primary: PRIMARY,
  secondary: SECONDARY,
  neutral: NEUTRAL,
  error: ERROR,
  warning: WARNING,
  success: SUCCESS,
  link: LINK,
  background: BACKGROUND,
  chart: CHART_COLORS,
  table: TABLE,
  black: BLACK,
};

const base = createTheme({
  mode: 'dark',
  palette: customPalette,
});

export default base;
