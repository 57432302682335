import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Alert } from '@mui/material';
import IntegrationCard from './IntegrationCard';
import SlackButton from './IntegrationButtons/Slack';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchIntegrationStatus = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchIntegrationStatus = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    try {
      const token = await getAccessTokenSilently();
      const timestamp = Date.now(); // Get the current timestamp
      const response = await axios.get(`/api/v1/integrations/?_=${timestamp}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (err) {
      console.error(err);
      setError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently]);

  return { fetchIntegrationStatus, isLoading, error };
};

const IntegrationsView = () => {
  const initialIntegrations = [
    { id: 1, title: 'Slack', isConnected: false, description: 'Send alerts to your Slack channels.' },
    // Add more integrations here
  ];

  const [integrations, setIntegrations] = useState(initialIntegrations);
  const { fetchIntegrationStatus, isLoading, error } = useFetchIntegrationStatus();

  const handleSlackConnectionChange = (isConnected) => {
    setIntegrations((currentIntegrations) =>
      currentIntegrations.map((integration) =>
        integration.title === 'Slack' ? { ...integration, isConnected } : integration
      )
    );
  };

  useEffect(() => {
    const updateIntegrationsStatus = async () => {
      const response = await fetchIntegrationStatus();
      const isSlackConnected = response && response.slack === true;
      setIntegrations((currentIntegrations) =>
        currentIntegrations.map((integration) =>
          integration.title === 'Slack' ? { ...integration, isConnected: isSlackConnected } : integration
        )
      );
    };

    updateIntegrationsStatus();
  }, [fetchIntegrationStatus]);

  const buttonComponents = {
    Slack: SlackButton,
    // Add other integrations here
  };

  return (
    <Grid container spacing={2}>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">Error checking integrations. Please try again later.</Alert>
        </Grid>
      )}
      {integrations.map((integration) => {
        const ButtonComponent = buttonComponents[integration.title] || null;
        return (
          <Grid item key={integration.id} xs={12} sm={6} md={4}>
            <IntegrationCard
              title={integration.title}
              isConnected={integration.isConnected}
              description={integration.description}
              ButtonComponent={ButtonComponent}
              isLoading={isLoading}
              onConnectionChange={handleSlackConnectionChange}
              error={error}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default IntegrationsView;
