import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import slack from '../../../../assets/integrations/slack.webp';

const SlackButton = ({ isConnected, onConnectionChange, disabled }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const connectToSlack = async (code) => {
    try {
      const response = await axios.post('/api/v1/integrations/slack', { code });
      return response.statusText === 'OK' && response.status === 200;
    } catch (error) {
      console.error('Error connecting to Slack:', error);
      throw error;
    }
  };

  const disconnectFromSlack = async (token) => {
    try {
      const response = await axios.delete('/api/v1/integrations/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.statusText === 'OK' && response.status === 200;
    } catch (error) {
      console.error('Error disconnecting from Slack:', error);
      throw error;
    }
  };

  const handleConnect = () => {
    setIsConnecting(true);
    const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
    const scopes = 'channels:read, chat:write, chat:write.public, commands, groups:read, im:read, mpim:read';
    const redirectUri = `${window.location.origin}/ui/slack/redirect`;
    const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}`;
    window.location.href = slackUrl;
  };

  const handleSlackRedirect = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      try {
        const isConnected = await connectToSlack(code);
        if (isConnected) {
          onConnectionChange(true);
        } else {
          // Display an error message to the user
        }
      } catch (error) {
        console.error('Error connecting to Slack:', error);
        // Display an error message to the user
      } finally {
        setIsConnecting(false);
      }
    }
  }, [onConnectionChange]);

  const handleDisconnect = async () => {
    setIsDisconnecting(true);
    try {
      const token = await getAccessTokenSilently();
      const isDisconnected = await disconnectFromSlack(token);
      if (isDisconnected) {
        onConnectionChange(false);
      } else {
        // Display an error message to the user
      }
    } catch (error) {
      console.error('Error disconnecting from Slack:', error);
      // Display an error message to the user
    } finally {
      setIsDisconnecting(false);
    }
  };

  // Call handleSlackRedirect on component mount
  useEffect(() => {
    handleSlackRedirect();
  }, [handleSlackRedirect]);

  return (
    <>
      {isConnected ? (
        <Button
          variant="contained"
          color="error"
          onClick={handleDisconnect}
          disabled={isDisconnecting || disabled}
          style={{ opacity: disabled ? 0.5 : 1 }}>
          {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
        </Button>
      ) : (
        <Button
          variant="contained"
          startIcon={<img src={slack} alt="Slack" style={{ width: '20px', height: '20px', marginRight: '8px' }} />}
          style={{
            backgroundColor: 'white',
            color: 'black',
            '&:hover': { backgroundColor: '#4A154B' },
            opacity: disabled ? 0.5 : 1,
          }}
          onClick={handleConnect}
          disabled={isConnecting || disabled}>
          {isConnecting ? (
            <Typography color="black" fontWeight="bold">
              Connecting...
            </Typography>
          ) : (
            <Typography color="black" fontWeight="bold">
              Add to Slack
            </Typography>
          )}
        </Button>
      )}
    </>
  );
};

SlackButton.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  onConnectionChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SlackButton.defaultProps = {
  disabled: false,
};

export default SlackButton;
