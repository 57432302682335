import React, { useCallback } from 'react';
import { useKPI } from '../../../providers/AlertKPIProvider';
import IconButton from '@mui/material/IconButton';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { useAlertsContext } from '../../../providers/AlertsProvider';
import { useTheme } from '@mui/material/styles';

const CreateAlertButton = ({ kpiName, isHovered, onHoverChange }) => {
  const { setKPI } = useKPI();
  const { setIsModalOpen } = useAlertsContext();
  const theme = useTheme();

  const openAlertModal = useCallback(() => {
    setKPI(kpiName);
    setIsModalOpen(true);
    if (onHoverChange) {
      onHoverChange(false);
    }
  }, [kpiName, onHoverChange, setKPI, setIsModalOpen]);

  const buttonStyle = {
    opacity: isHovered !== undefined ? (isHovered ? 1 : 0.2) : 1,
    backgroundColor: isHovered !== undefined ? (isHovered ? theme.palette.primary : 'transparent') : 'transparent',
    color:
      isHovered !== undefined
        ? isHovered
          ? theme.palette.primary.light
          : theme.palette.contrastText
        : theme.palette.primary.light,
    transition:
      isHovered !== undefined
        ? 'opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out'
        : 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      opacity: 1,
    },
  };

  return (
    <>
      <IconButton onClick={openAlertModal} aria-label="create alert" size="small" sx={buttonStyle}>
        <NotificationAddIcon />
      </IconButton>
    </>
  );
};

export default React.memo(CreateAlertButton);
