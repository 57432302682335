import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Badge } from '@mui/material';
import TrendingToolbarColumnsModal from '../modals/TrendingToolbarColumnsModal';
import TrendingToolbarFiltersModal from '../modals/TrendingToolbarFiltersModal';
import CreateAlertButton from '../settings/Alerts/CreateAlertButton';

const TrendingToolbar = (props) => {
  const [filterValuesChanged, setFilterValuesChanged] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedAuthor, setSelectedAuthor] = useState('');
  const [selectedContentTypes, setSelectedContentTypes] = useState([{ id: 'article', label: 'Article' }]);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleColumnsOpen = () => setColumnsOpen(true);
  const handleColumnsClose = () => setColumnsOpen(false);

  const handleFiltersOpen = () => setFiltersOpen(true);
  const handleFiltersClose = () => setFiltersOpen(false);

  const handleColumnChange = (newvalue) => {
    props.setColumnVisibility(newvalue);
  };

  const handleSectionChange = (newValue) => {
    setSelectedSection(newValue);
  };

  const handleAuthorChange = (newValue) => {
    setSelectedAuthor(newValue);
  };

  const handleContentTypeChange = (newValue) => {
    setSelectedContentTypes(newValue);
  };

  const handleSourceChange = (newValue) => {
    setSelectedSource(newValue);
  };

  const handlePlatformChange = (newValue) => {
    setSelectedPlatform(newValue);
  };

  const applySearch = () => {
    props.handleSearchTermChange(searchTerm);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      applySearch();
      e.preventDefault();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleTrendingFullscreen = () => {
    props.toggleTrendingFullscreen();
  };

  const applyFilters = () => {
    if (filterValuesChanged) {
      setFilterValuesChanged(false);
      props.handleSearchTermChange(searchTerm);
      props.handleSectionChange(selectedSection);
      props.handleAuthorChange(selectedAuthor);
      props.handleContentTypeChange(selectedContentTypes);
      props.handleSourceChange(selectedSource);
      props.handlePlatformChange(selectedPlatform);
    }
  };

  const updateFilterCount = () => {
    let count = 0;
    if (selectedSection !== '') {
      count++;
    }
    if (selectedAuthor !== '') {
      count++;
    }
    if (Array.isArray(selectedContentTypes) && selectedContentTypes.length) {
      count++;
    }
    if (selectedSource !== '') {
      count++;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    if (props.selectedSection) {
      setSelectedSection(props.selectedSection);
    }
  }, [props.selectedSection]);

  useEffect(() => {
    if (props.selectedAuthor) {
      setSelectedAuthor(props.selectedAuthor);
    }
  }, [props.selectedAuthor]);

  useEffect(() => {
    if (props.selectedContentTypes) {
      setSelectedContentTypes(props.selectedContentTypes);
    }
  }, [props.selectedContentTypes]);

  useEffect(() => {
    if (props.selectedSource) {
      setSelectedSource(props.selectedSource);
    }
  }, [props.selectedSource]);

  useEffect(() => {
    if (props.selectedPlatform) {
      setSelectedPlatform(props.selectedPlatform);
    }
  }, [props.selectedPlatform]);

  useEffect(() => {
    setFilterValuesChanged(
      selectedSection !== props.selectedSection ||
        selectedAuthor !== props.selectedAuthor ||
        JSON.stringify(selectedContentTypes) !== JSON.stringify(props.selectedContentTypes) ||
        selectedSource !== props.selectedSource ||
        selectedPlatform !== props.selectedPlatform
    );
    updateFilterCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSection,
    selectedAuthor,
    selectedContentTypes,
    selectedSource,
    selectedPlatform,
    props.selectedSection,
    props.selectedAuthor,
    props.selectedContentTypes,
    props.selectedSource,
    props.selectedPlatform,
  ]);

  return (
    <Box
      sx={{
        mb: { sm: 2 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: { xs: 'flex-start', sm: 'flex-end' },
        alignItems: 'center',
      }}>
      <TextField
        type="search"
        size="small"
        label="Search"
        disabled={props.disabled}
        onKeyPress={handleSearchKeyPress}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="search-button" onClick={applySearch} edge="end" disabled={props.disabled}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          width: '100%',
          maxWidth: '200px',
          mr: 1,
        }}
      />

      <Box sx={{ flexGrow: { xs: '1', sm: '0' } }} />

      {props.columns && (
        <Box>
          <IconButton aria-label="columns" onClick={handleColumnsOpen} disabled={props.disabled}>
            <ViewColumnIcon />
          </IconButton>
          <TrendingToolbarColumnsModal
            columnsOpen={columnsOpen}
            handleColumnsOpen={handleColumnsOpen}
            handleColumnsClose={handleColumnsClose}
            columns={props.columns}
            updateVisibility={handleColumnChange}
            columnVisibility={props.columnVisibility}
            setUserChangedColumnVisibility={props.setUserChangedColumnVisibility}
          />
        </Box>
      )}

      <Badge badgeContent={filterCount} color="primary" overlap="circular" invisible={props.disabled}>
        <IconButton aria-label="filters" onClick={handleFiltersOpen} disabled={props.disabled}>
          <TuneIcon />
        </IconButton>
      </Badge>
      <TrendingToolbarFiltersModal
        filtersOpen={filtersOpen}
        handleFilterssOpen={handleFiltersOpen}
        handleFiltersClose={handleFiltersClose}
        handleSectionChange={handleSectionChange}
        handleAuthorChange={handleAuthorChange}
        handleContentTypeChange={handleContentTypeChange}
        handleSourceChange={handleSourceChange}
        handlePlatformChange={handlePlatformChange}
        applyFilters={applyFilters}
        selectedSection={selectedSection}
        selectedContentTypes={selectedContentTypes}
        selectedSource={selectedSource}
        selectedAuthor={selectedAuthor}
        selectedPlatform={selectedPlatform}
      />

      <IconButton
        aria-label="full-screen"
        onClick={toggleTrendingFullscreen}
        edge={'end'}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        disabled={props.disabled}>
        {props.isTrendingFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
      <Box ml={2}>
        <CreateAlertButton kpiName="Trending Metrics" />
      </Box>
    </Box>
  );
};

export default TrendingToolbar;
